import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { SearchBar } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import DateRangeSelector from '../../DSTS004/DTST004_List_Child/DateRangeSelector';
import { SendStatusDropdown } from './SendStatusDropdown';
const SendSMSTitleStyled = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;

	.active-bar {
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		justify-content: flex-end;
		flex-shrink: 0;
		position: relative;

		.field-global-001 {
			flex-wrap: wrap;
			justify-content: flex-end;

			${props => props.$isMobile &&
    `
				> * {
					width: 100%;
				}
			`}
		}

		${props => props.$isTablet && `
			.dropdown-field {
				gap: 0px;
				padding: 10px 12px;
				height: 35px;
			}	
			
			.date-field {
				input {
					height: 35px;
					width: 110px !important;
				}
			}

			.search-bar-qna {
				padding: 8px 12px;
				height: 35px;
				gap: 2px;
				input {
					width: 130px;
				}
				input::placeholder {
					font-size: 12px;
				}	
			}
		`}
	}

	.divider {
		background: var(--color-grey-c, #e6e8ed);
		flex-shrink: 0;
		width: 1px;
		height: 42px;
		position: relative;
	}

	.search-bar {
		input {
			width: 159px;
		}
	}
`;
export const SendSMSTitle = React.memo(({ keyword, sendStauts, startDate, endDate, setSendStatus, setStartDate, setEndDate, setKeyword, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const endRef = React.useRef(null);
    const startRef = React.useRef(null);
    const handlerCheckStartDate = startDate => {
        if (moment(startDate).isAfter(endDate)) {
            alert(`${endDate} 이전의 날짜를 선택해주세요.`);
            startRef.current.value = '';
            return;
        }
        else {
            setStartDate(startDate);
            setEndDate(endDate);
        }
    };
    const handlerCheckEndDate = endDate => {
        if (moment(endDate).isBefore(startDate)) {
            alert(`${startDate} 이후의 날짜를 선택해주세요.`);
            endRef.current.value = '';
            return;
        }
        else {
            setEndDate(endDate);
            setStartDate(startDate);
        }
    };
    return (React.createElement(SendSMSTitleStyled, { className: "send-sms-title", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement("div", { className: "active-bar" },
            React.createElement("div", { className: "field-global-001", style: {
                    flexDirection: isMobile ? 'column' : 'row',
                    width: isDesktop && 'unset',
                } },
                React.createElement(SendStatusDropdown, { sendStatus: sendStauts, setSendStatus: setSendStatus }),
                isDesktop && React.createElement("div", { className: "divider" }),
                React.createElement("div", { className: "date-field" },
                    React.createElement(DateRangeSelector, { defaultStartDate: startDate, defaultEndDate: endDate, setStartDate: setStartDate, setEndDate: setEndDate })),
                isDesktop && React.createElement("div", { className: "divider" }),
                React.createElement(SearchBar, { className: `${!isMobile ? `search-bar-qna ${isTablet && 'sm'}` : 'search-bar-mobile'}`, filterData: "name", handlerFilterList: (value) => {
                        // @ts-ignore
                        setKeyword(value.name);
                    } })))));
});
