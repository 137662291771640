import React from 'react';
//@ts-ignore
import Logo from '../../assest/icon/logo.svg';
//@ts-ignore
import LogoWhite from '../../assest/icon/logo_white.svg';
//@ts-ignore
import Out from '../../assest/icon/out.svg';
//@ts-ignore
import AnswerCount from '../../assest/icon/answer-count.svg';
//@ts-ignore
import TotalCount from '../../assest/icon/total-count.svg';
//@ts-ignore
import QuestionIcon from '../../assest/icon/Q.svg';
//@ts-ignore
import AnswerIcon from '../../assest/icon/A.svg';
//@ts-ignore
import MinusRing from '../../assest/icon/ic-minus-ring.svg';
//@ts-ignore
import Pdf from '../../assest/icon/ic-pdf.svg';
//@ts-ignore
import Zip from '../../assest/icon/ic-zip.svg';
//@ts-ignore
import VodPause from '../../assest/icon/ic_pause.svg';
//@ts-ignore
import VodStart from '../../assest/icon/ic_play.svg';
//@ts-ignore
import VodVolumeOff from '../../assest/icon/ic_soundoff.svg';
//@ts-ignore
import VodVolumeOn from '../../assest/icon/ic_soundon.svg';
// @ts-ignore
import AttendTitle from '../../assest/icon/ic-attend-title.svg';
// @ts-ignore
import TaskTitle from '../../assest/icon/ic-task-title.svg';
// @ts-ignore
import TestTitle from '../../assest/icon/ic-test-title.svg';
//@ts-ignore
import Profile from '../../assest/icon/profile-pic.svg';
//@ts-ignore
import Teacher from '../../assest/icon/teacher.svg';
//@ts-ignore
import TwoArrowIcon from '../../assest/icon/ic-2arrow.svg';
//@ts-ignore
import ArrowIcon from '../../assest/icon/ic-arrow.svg';
//@ts-ignore
import FullArrowIcon from '../../assest/icon/ic-full-arrow.svg';
//@ts-ignore
import SearchIcon from '../../assest/icon/ic-search.svg';
//@ts-ignore
import CheckIcon from '../../assest/icon/ic-checked.svg';
//@ts-ignore
import UnCheckedIcon from '../../assest/icon/ic-unchecked.svg';
//@ts-ignore
import File from '../../assest/icon/ic-file.svg';
//@ts-ignore
import Cancel from '../../assest/icon/ic-cancel.svg';
//@ts-ignore
import Close from '../../assest/icon/ic-close.svg';
// @ts-ignore
import NoneIcon from '../../assest/icon/ic-none.svg';
//@ts-ignore
import Message from '../../assest/icon/ic-message.svg';
//@ts-ignore
import PenEdit from '../../assest/icon/ic-pen-edit.svg';
//@ts-ignore
import AddSurvey from '../../assest/icon/ic-add-survey.svg';
//@ts-ignore
import DelSurvey from '../../assest/icon/ic-del-survey.svg';
// @ts-ignore
import RadioChecked from '../../assest/icon/ic-radio-checked.svg';
// @ts-ignore
import RadioUnchecked from '../../assest/icon/ic-radio-unchecked.svg';
// @ts-ignore
import Edit from '../../assest/icon/ic-edit.svg';
// @ts-ignore
import Delete from '../../assest/icon/ic-delete.svg';
// @ts-ignore
import AssignmentOn from '../../assest/icon/menu/ic-assignment-on.svg';
// @ts-ignore
import AssignmentOff from '../../assest/icon/menu/ic-assignment-off.svg';
// @ts-ignore
import TestOn from '../../assest/icon/menu/ic-test-on.svg';
// @ts-ignore
import TestOff from '../../assest/icon/menu/ic-test-off.svg';
// @ts-ignore
import ReservationOn from '../../assest/icon/menu/ic-reservation-on.svg';
// @ts-ignore
import ReservationOff from '../../assest/icon/menu/ic-reservation-off.svg';
// @ts-ignore
import PreviousConferenceON from '../../assest/icon/menu/ic-previous-conference-on.svg';
// @ts-ignore
import PreviousConferenceOff from '../../assest/icon/menu/ic-previous-conference-off.svg';
// @ts-ignore
import NoticeOn from '../../assest/icon/menu/ic-notice-on.svg';
// @ts-ignore
import NoticeOff from '../../assest/icon/menu/ic-notice-off.svg';
// @ts-ignore
import QnaOn from '../../assest/icon/menu/ic-qna-on.svg';
// @ts-ignore
import QnaOff from '../../assest/icon/menu/ic-qna-off.svg';
// @ts-ignore
import ResourceOn from '../../assest/icon/menu/ic-resource-on.svg';
// @ts-ignore
import ResourceOff from '../../assest/icon/menu/ic-resource-off.svg';
// @ts-ignore
import ContentOn from '../../assest/icon/menu/ic-content-on.svg';
// @ts-ignore
import ContentOff from '../../assest/icon/menu/ic-content-off.svg';
// @ts-ignore
import ReplyOn from '../../assest/icon/menu/ic-reply-on.svg';
// @ts-ignore
import ReplyOff from '../../assest/icon/menu/ic-reply-off.svg';
// @ts-ignore
import AttendanceStatusOn from '../../assest/icon/menu/ic-attendanceStatus-on.svg';
// @ts-ignore
import AttendanceStatusOff from '../../assest/icon/menu/ic-attendanceStatus-off.svg';
// @ts-ignore
import SendSMSOn from '../../assest/icon/menu/ic-sendSMS-on.svg';
// @ts-ignore
import SendSMSOff from '../../assest/icon/menu/ic-sendSMS-off.svg';
// @ts-ignore
import GradeOn from '../../assest/icon/menu/ic-grade-on.svg';
// @ts-ignore
import GradeOff from '../../assest/icon/menu/ic-grade-off.svg';
// @ts-ignore
import Pass from '../../assest/icon/ic-pass.svg';
// @ts-ignore
import Fail from '../../assest/icon/ic-union.svg';
//@ts-ignore
import DetailDelete from '../../assest/icon/ic-detail-delete.svg';
//@ts-ignore
import CheckCircle from '../../assest/icon/ic-check-in-circle.svg';
//@ts-ignore
import CheckEqual from '../../assest/icon/ic-check-in-equal.svg';
//@ts-ignore
import FileDown from '../../assest/icon/ic-file-down.svg';
//@ts-ignore
import AddFolder from '../../assest/icon/ic-add-folder.svg';
//@ts-ignore
import AddRing from '../../assest/icon/ic-add-ring.svg';
//@ts-ignore
import Locked from '../../assest/icon/ic-locked.svg';
//@ts-ignore
import Document from '../../assest/icon/ic-document.svg';
//@ts-ignore
import Video from '../../assest/icon/ic-video.svg';
//@ts-ignore
import Background from '../../assest/icon/ic-background.svg';
//@ts-ignore
import Plus from '../../assest/icon/ic-plus.svg';
//@ts-ignore
import View from '../../assest/icon/ic-view-eye.svg';
//@ts-ignore
import HomeOn from '../../assest/icon/mobile-menu/ic-home-on.svg';
//@ts-ignore
import HomeOff from '../../assest/icon/mobile-menu/ic-home-off.svg';
//@ts-ignore
import LiveOn from '../../assest/icon/mobile-menu/ic-live-on.svg';
//@ts-ignore
import LiveOff from '../../assest/icon/mobile-menu/ic-live-off.svg';
//@ts-ignore
import LogoutOn from '../../assest/icon/mobile-menu/ic-logout-on.svg';
//@ts-ignore
import LogoutOff from '../../assest/icon/mobile-menu/ic-logout-off.svg';
//@ts-ignore
import ManagementOn from '../../assest/icon/mobile-menu/ic-management-on.svg';
//@ts-ignore
import ManagementOff from '../../assest/icon/mobile-menu/ic-management-off.svg';
//@ts-ignore
import MyClassOn from '../../assest/icon/mobile-menu/ic-myclass-on.svg';
//@ts-ignore
import MyClassOff from '../../assest/icon/mobile-menu/ic-myclass-off.svg';
//@ts-ignore
import SupportOn from '../../assest/icon/mobile-menu/ic-support-on.svg';
//@ts-ignore
import SupportOff from '../../assest/icon/mobile-menu/ic-support-off.svg';
//@ts-ignore
import MenuOpen from '../../assest/icon/mobile-menu/ic-menu-open.svg';
//@ts-ignore
import MenuClose from '../../assest/icon/mobile-menu/ic-menu-close.svg';
//@ts-ignore
import Question from '../../assest/icon/ic-question.svg';
//@ts-ignore
import Answer from '../../assest/icon/ic-answer.svg';
//@ts-ignore
import PageOff from '../../assest/icon/ic-page-off.svg';
//@ts-ignore
import PageOn from '../../assest/icon/ic-page-on.svg';
//@ts-ignore
import CancelMobile from '../../assest/icon/ic-cancel-mobile.svg';
//@ts-ignore
import NoImage from '../../assest/icon/ic-no-image.svg';
//@ts-ignore
import DeleteUsers from '../../assest/icon/ic-delete-users.svg';
//@ts-ignore
import AddUsers from '../../assest/icon/ic-add-users.svg';
//@ts-ignore
import CircleDelete from '../../assest/icon/ic-delete-in-circle.svg';
//@ts-ignore
import CirclePlus from '../../assest/icon/ic-plus-in-circle.svg';
//@ts-ignore
import Dash from '../../assest/icon/ic-dash.svg';
//@ts-ignore
import FileUpload from '../../assest/icon/ic-file-upload.svg';
//@ts-ignore
import FullScreenOff from '../../assest/icon/fullscreen_off.svg';
//@ts-ignore
import FullScreenOn from '../../assest/icon/fullscreen_on.svg';
//@ts-ignore
import ArrowInCircleLeft from '../../assest/icon/ic-arrow-in-circle-left.svg';
//@ts-ignore
import ArrowInCircleRight from '../../assest/icon/ic-arrow-in-circle-right.svg';
const Icon = React.memo(({ className, style, icon, size = 20, width = size, height = size, stroke, fill, }) => {
    switch (icon) {
        case 'out':
            return React.createElement(Out, { width: width, height: height, stroke: stroke, fill: fill });
        case 'answerCount':
            return React.createElement(AnswerCount, { width: width, height: height, stroke: stroke, fill: fill });
        case 'totalCount':
            return React.createElement(TotalCount, { width: width, height: height, stroke: stroke, fill: fill });
        case 'question-icon':
            return React.createElement(QuestionIcon, { width: width, height: height, stroke: stroke, fill: fill });
        case 'answer-icon':
            return React.createElement(AnswerIcon, { width: width, height: height, stroke: stroke, fill: fill });
        case 'none':
            return React.createElement(NoneIcon, { className: className, width: width, height: height, fill: fill });
        case 'attend-title':
            return React.createElement(AttendTitle, { className: className, width: width, height: height });
        case 'task-title':
            return React.createElement(TaskTitle, { className: className, width: width, height: height });
        case 'test-title':
            return React.createElement(TestTitle, { className: className, width: width, height: height });
        case 'logo':
            return React.createElement(Logo, { className: className, width: width, height: height });
        case 'logo_white':
            return React.createElement(LogoWhite, { className: className, width: width, height: height });
        case 'profile':
            return React.createElement(Profile, { width: width, height: height, stroke: stroke, fill: fill });
        case 'teacher':
            return React.createElement(Teacher, { width: width, height: height, stroke: stroke, fill: fill });
        case 'twoarrow':
            return (React.createElement(TwoArrowIcon, { className: className, style: style, width: width, height: height, stroke: stroke, fill: fill }));
        case 'arrow':
            return (React.createElement(ArrowIcon, { className: className, style: style, width: width, height: height, stroke: stroke, fill: fill }));
        case 'fullArrow':
            return (React.createElement(FullArrowIcon, { className: className, style: style, width: width, height: height, stroke: stroke }));
        case 'vodStart':
            return React.createElement(VodStart, { width: size, height: size, stroke: stroke, fill: fill });
        case 'vodPause':
            return React.createElement(VodPause, { width: size, height: size, stroke: stroke, fill: fill });
        case 'vodVolumeOn':
            return React.createElement(VodVolumeOn, { width: size, height: size, stroke: stroke, fill: fill });
        case 'vodVolumeOff':
            return React.createElement(VodVolumeOff, { width: size, height: size, stroke: stroke, fill: fill });
        case 'search':
            return React.createElement(SearchIcon, { width: width, height: height, stroke: stroke, fill: fill });
        case 'checked':
            return React.createElement(CheckIcon, { width: width, height: height, stroke: stroke, fill: fill });
        case 'unchecked':
            return React.createElement(UnCheckedIcon, { width: width, height: height, stroke: stroke, fill: fill });
        case 'file':
            return React.createElement(File, { width: width, height: height, stroke: stroke, fill: fill });
        case 'cancel':
            return React.createElement(Cancel, { width: width, height: height, stroke: stroke, fill: fill });
        case 'close':
            return (React.createElement(Close, { className: className, width: width, height: height, stroke: stroke, fill: fill }));
        case 'message':
            return React.createElement(Message, { width: width, height: height, stroke: stroke, fill: fill });
        case 'penEdit':
            return React.createElement(PenEdit, { width: width, height: height, stroke: stroke, fill: fill });
        case 'add-survey':
            return React.createElement(AddSurvey, { width: width, height: height, stroke: stroke, fill: fill });
        case 'del-survey':
            return React.createElement(DelSurvey, { width: width, height: height, stroke: stroke });
        case 'radio_checked':
            return React.createElement(RadioChecked, { width: width, height: height, stroke: stroke, fill: fill });
        case 'radio_unchecked':
            return React.createElement(RadioUnchecked, { width: width, height: height, stroke: stroke, fill: fill });
        case 'edit':
            return React.createElement(Edit, { width: width, height: height, stroke: stroke, fill: fill });
        case 'delete':
            return React.createElement(Delete, { width: width, height: height, stroke: stroke, fill: fill });
        case 'assignment_on':
            return React.createElement(AssignmentOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'assignment_off':
            return React.createElement(AssignmentOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'test_on':
            return React.createElement(TestOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'test_off':
            return React.createElement(TestOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'attendanceStatus_on':
            return (React.createElement(AttendanceStatusOn, { width: width, height: height, stroke: stroke, fill: fill }));
        case 'attendanceStatus_off':
            return (React.createElement(AttendanceStatusOff, { width: width, height: height, stroke: stroke, fill: fill }));
        case 'reservation_on':
            return React.createElement(ReservationOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'reservation_off':
            return React.createElement(ReservationOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'previous-conference_on':
            return (React.createElement(PreviousConferenceON, { width: width, height: height, stroke: stroke, fill: fill }));
        case 'previous-conference_off':
            return (React.createElement(PreviousConferenceOff, { width: width, height: height, stroke: stroke, fill: fill }));
        case 'reply_on':
            return React.createElement(ReplyOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'reply_off':
            return React.createElement(ReplyOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'gradeCard_on':
            return React.createElement(GradeOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'gradeCard_off':
            return React.createElement(GradeOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'sendSMS_on':
            return React.createElement(SendSMSOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'sendSMS_off':
            return React.createElement(SendSMSOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'notice_on':
            return React.createElement(NoticeOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'notice_off':
            return React.createElement(NoticeOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'qna_on':
            return React.createElement(QnaOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'qna_off':
            return React.createElement(QnaOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'resource_on':
            return React.createElement(ResourceOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'resource_off':
            return React.createElement(ResourceOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'content_on':
            return React.createElement(ContentOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'content_off':
            return React.createElement(ContentOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'pass':
            return React.createElement(Pass, { width: width, height: height, stroke: stroke, fill: fill });
        case 'fail':
            return React.createElement(Fail, { width: width, height: height, stroke: stroke, fill: fill });
        case 'detail-delete':
            return React.createElement(DetailDelete, { width: width, height: height, stroke: stroke, fill: fill });
        case 'check-circle':
            return React.createElement(CheckCircle, { width: width, height: height, stroke: stroke, fill: fill });
        case 'check-equal':
            return React.createElement(CheckEqual, { width: width, height: height, stroke: stroke, fill: fill });
        case 'file-down':
            return React.createElement(FileDown, { width: width, height: height, stroke: stroke, fill: fill });
        case 'add_folder':
            return (React.createElement(AddFolder, { style: style, width: width, height: height, stroke: stroke, fill: fill }));
        case 'add_ring':
            return (React.createElement(AddRing, { style: style, dwidth: width, height: height, stroke: stroke, fill: fill }));
        case 'minus_ring':
            return (React.createElement(MinusRing, { style: style, dwidth: width, height: height, stroke: stroke, fill: fill }));
        case 'locked':
            return (React.createElement(Locked, { style: style, width: width, height: height, stroke: stroke, fill: fill }));
        case 'document':
            return (React.createElement(Document, { style: style, width: width, height: height, stroke: stroke, fill: fill }));
        case 'video':
            return (React.createElement(Video, { style: style, width: width, height: height, stroke: stroke, fill: fill }));
        case 'background':
            return (React.createElement(Background, { style: style, width: width, height: height, stroke: stroke, fill: fill }));
        case 'plus':
            return React.createElement(Plus, { width: width, height: height, stroke: stroke, fill: fill });
        case 'home_on':
            return React.createElement(HomeOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'home_off':
            return React.createElement(HomeOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'liveRoom_on':
            return React.createElement(LiveOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'liveRoom_off':
            return React.createElement(LiveOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'logout_on':
            return React.createElement(LogoutOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'logout_off':
            return React.createElement(LogoutOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'learningManagement_on':
            return React.createElement(ManagementOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'learningManagement_off':
            return React.createElement(ManagementOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'myRoom_on':
            return React.createElement(MyClassOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'myRoom_off':
            return React.createElement(MyClassOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'learningSupportRoom_on':
            return React.createElement(SupportOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'learningSupportRoom_off':
            return React.createElement(SupportOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'menu_close':
            return (React.createElement(MenuClose, { className: className, width: width, height: height, stroke: stroke, fill: fill }));
        case 'menu_open':
            return React.createElement(MenuOpen, { width: width, height: height, stroke: stroke, fill: fill });
        case 'view':
            return React.createElement(View, { width: width, height: height, stroke: stroke, fill: fill });
        case 'no_pdf':
            return (React.createElement(Pdf, { className: className, width: width, height: height, stroke: stroke, fill: fill }));
        case 'no_zip':
            return (React.createElement(Zip, { className: className, width: width, height: height, stroke: stroke, fill: fill }));
        case 'question':
            return (React.createElement(Question, { className: className, width: width, height: height, stroke: stroke, fill: fill }));
        case 'answer':
            return (React.createElement(Answer, { className: className, width: width, height: height, stroke: stroke, fill: fill }));
        case 'page_off':
            return React.createElement(PageOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'page_on':
            return React.createElement(PageOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'cancel-mobile':
            return React.createElement(CancelMobile, { width: width, height: height, stroke: stroke, fill: fill });
        case 'no_image':
            return React.createElement(NoImage, { width: width, height: height, stroke: stroke, fill: fill });
        case 'delete_users':
            return React.createElement(DeleteUsers, { width: width, height: height, stroke: stroke, fill: fill });
        case 'add_users':
            return React.createElement(AddUsers, { width: width, height: height, stroke: stroke, fill: fill });
        case 'circle_delete':
            return React.createElement(CircleDelete, { width: width, height: height, stroke: stroke, fill: fill });
        case 'circle_plus':
            return React.createElement(CirclePlus, { width: width, height: height, stroke: stroke, fill: fill });
        case 'dash':
            return React.createElement(Dash, { width: width, height: height, stroke: stroke, fill: fill });
        case 'file_upload':
            return React.createElement(FileUpload, { width: width, height: height, stroke: stroke, fill: fill });
        case 'fullScreen_off':
            return React.createElement(FullScreenOff, { width: width, height: height, stroke: stroke, fill: fill });
        case 'fullScreen_on':
            return React.createElement(FullScreenOn, { width: width, height: height, stroke: stroke, fill: fill });
        case 'arrow_in_circle_left':
            return React.createElement(ArrowInCircleLeft, { width: width, height: height, stroke: stroke, fill: fill });
        case 'arrow_in_circle_right':
            return React.createElement(ArrowInCircleRight, { width: width, height: height, stroke: stroke, fill: fill });
        default:
            return null;
    }
});
export default Icon;
