import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Table } from '../../../../common';
import { EmptyTable } from '../../../../common/option';
import { useGlobalState, useHistoryStore } from '../../../../GlobalStateProvider';
import { useSendSMSList } from '../../../../server/sms';
import { changeMemuProps } from '../../../menu/action';
import { DSTL007_Detail } from '../DSTL007_Detail';
import { SendSMSTitle } from './SendSMSTitle';
const SendSMSTableStyles = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	position: relative;
	width: 100%;
	overflow: auto;

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.body-item {
		.pink {
			color: var(--color-pink, #f46790);
		}

		.tur {
			color: var(--tur-d, #479da6);
		}
	}

	.bold-tur {
		p {
			color: var(--tur-d, #479da6);
			font-weight: 700;
		}
	}

	.sms-list-001 {
		width: 100%;
		overflow: auto;
	}

	.locked-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.title-wrap {
		width: 100%;
	}
	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	gap: 12px;

	.title-wrap {
		display: flex;
		flex-direction: column;
		gap: 12px;
		padding: 12px 20px 12px 20px;
		background: var(--color-silver, #f3f4f8);

		.title-button-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			.title {
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
			}
		}
	}

	.sms-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		padding: 0 20px 20px 20px;
		.scroll-content {
			overflow: unset;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b3-font-size, 12px);
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.button {
				p {
					font-size: var(--b3-font-size, 10px);
					color: var(--color-white, #ffffff);
					width: 30px;
					height: 17px;
					display: flex;
					justify-content: center;
				}
			}

			.bold {
				font-size: 14px;
				font-weight: 700;
			}

			.grey {
				color: var(--color-grey-l, #83868a);
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;
			padding: 12px 0px 12px 0px;
			gap: 4px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			&:nth-child(1) {
				border: none;
			}
		}

		.open-contents {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;
		}
	}

	.qna-list-wrap {
		display: flex;
		flex-direction: row;
		gap: 6px;
	}

	.locked-wrap {
		gap: 4px;
		svg {
			min-width: 14px;
		}
	}
	.search-bar-big {
		height: 41px;
	}

	.divider {
		background: var(--color-grey-c, #e6e8ed);
		flex-shrink: 0;
		width: 1px;
		flex: 1;
		position: relative;
	}
`;
const tabletStyles = css `
	flex: 1;
	gap: 12px;
	min-width: 460px;

	.sms-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: var(--b3-font-weight, 400);
			}

			.button {
				p {
					font-size: var(--b3-font-size, 12px);
					color: var(--color-white, #ffffff);
					width: 45px;
					height: 21px;
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;

			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.search-bar-big {
		height: 41px;
	}

	.paging {
		gap: 8px;
		button {
			width: 25px;
			height: 25px;
			p {
				min-width: unset;
			}
		}
	}
`;
const desktopStyles = css `
	flex: 2;
	gap: 24px;

	.sms-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 6px 28px 6px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.file {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				overflow: hidden;
				p {
					color: var(--color-grey-w, #b6bac0);
				}
			}

			.button {
				p {
					font-size: var(--b3-font-size, 14px);
					color: var(--color-white, #ffffff);
					width: 49px;
					height: 30px;
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;

			padding: 20px 28px 20px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
export const SendSMSListTable = React.memo(({ selectedId, setSelectedId, setReceiverMemberList, }) => {
    const { t } = useTranslation();
    const historyStore = useHistoryStore();
    const { isTablet, isMobile } = useGlobalState();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [count, setCount] = useState(10);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // 키워드 검색
    const [keyword, setKeyword] = useState('');
    // 발송 상태
    const [sendStauts, setSendStatus] = React.useState('select');
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'live_status', space: 1 },
            { title: 'just_type', space: 1 },
            // { title: 'live_title', space: 4, ...(isMobile ? {} : { maxWidth: 300 }) },
            { title: 'live_title', space: 5 },
            ...(isMobile ? [] : [{ title: 'sender', space: 1 }]),
            { title: 'sendDate', space: 2 },
        ];
        const tableReadDataKeys = [
            { title: 'status', space: 1 },
            { title: 'type', space: 1 },
            // { title: 'title', space: 4, ...(isMobile ? {} : { maxWidth: 300 }) },
            { title: 'title', space: 5 },
            ...(isMobile ? [] : [{ title: 'sender_name', space: 1 }]),
            { title: 'send_time', space: 2 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isMobile]);
    useEffect(() => {
        if (isMobile) {
            //history에 저장된 항목 초기화 (리스트 페이지일 때만) - 발송 -> 수정으로 이동 시 초기화
            const value = localStorage.getItem('learningManagement_props');
            if (value !== 'undefined') {
                const parsedValue = JSON.parse(value)?.historyItem?.props;
                if (parsedValue?.mobilePage === 'list' || parsedValue?.mobilePage === 'send') {
                    const historyItem = { props: undefined, pageName: 'mobileSend' };
                    historyStore.pushHistory(historyItem);
                }
            }
        }
        APP.eventManager.subscribe('UPDATE_SEND_SMS', refetchList);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_SEND_SMS', refetchList);
        };
    }, []);
    const { data, refetch } = useSendSMSList({
        ...(endDate
            ? {
                send_time_before: endDate
                    ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
                    : undefined,
            }
            : {}),
        ...(startDate
            ? {
                send_time_after: startDate
                    ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
                    : undefined,
            }
            : {}),
        ...(sendStauts === '' || sendStauts === 'select' ? {} : { status: sendStauts }),
        ...(keyword ? { keyword } : {}),
        offset: page === 0 ? 0 : page * count,
        limit: count,
        orderby: 'send_time DESC',
    });
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    useEffect(() => {
        setSelectedId('');
        refetchList();
    }, [startDate, endDate, keyword, sendStauts, page]);
    useEffect(() => {
        if (data) {
            const { total_count, items, found_count } = data;
            if (total_count > 0 && found_count === 0) {
                const maxPage = Math.max(0, Math.ceil(total_count / 10) - 1);
                setPage(maxPage);
                return;
            }
            if (found_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    const handlerMoreData = (page) => setPage(page);
    const renderTitle = useMemo(() => {
        return (React.createElement("div", { className: "title-wrap" },
            React.createElement(SendSMSTitle, { sendStauts: sendStauts, startDate: startDate, endDate: endDate, keyword: keyword, setSendStatus: setSendStatus, setStartDate: setStartDate, setEndDate: setEndDate, setKeyword: setKeyword })));
    }, []);
    const renderItem = (item, styles, index) => {
        const isSelcted = item.id === selectedId;
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: `${item.id} ${headItem.title}`, className: `body-item ${isSelcted ? 'bold-tur' : ''}`, style: {
                flex: headItem.space,
                maxWidth: headItem?.maxWidth ? headItem.maxWidth : 'auto',
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, headItem.title === 'status' ? (isMobile ? (React.createElement("div", { style: { display: 'flex', gap: 2 } },
            React.createElement("p", { className: "overText2" }, t(`sendSMS.${item[headItem.title]}`)),
            React.createElement("div", { className: "divider" }),
            React.createElement("p", { className: "overText2" }, item[headItem.title] === "normal" ? "일반" : "치환"))) : (React.createElement("p", { className: "overText2" }, t(`sendSMS.${item[headItem.title]}`)))) : headItem.title === 'content' ? (React.createElement("p", { className: `overText ${isMobile ? 'bold' : ''}` },
            " ",
            item.content,
            " ")) : headItem.title === 'send_time' ? (React.createElement(React.Fragment, null,
            isMobile && (React.createElement("p", { className: `overText2 ${selectedId === item.id ? 'bold-tur' : 'grey'}` }, item['sender'])),
            React.createElement("p", { className: `overText2 ${isMobile && (selectedId === item.id ? 'bold-tur' : 'grey')}` }, moment(item[headItem.title]).format('YYYY-MM-DD, HH:mm')))) : headItem.title === 'title' ? (React.createElement("p", { className: "overText2", style: { wordBreak: 'break-all', fontWeight: isMobile ? 700 : 400 } }, item[headItem.title] || item.content.slice(0, 20) + `${item.content.length > 20 ? '...' : ''}`)) : headItem.title === 'type' ? (isMobile ? (React.createElement(React.Fragment, null)) : (React.createElement("p", { className: "overText2" }, item[headItem.title] === "normal" ? "일반" : "치환"))) : (React.createElement("p", { className: "overText2" }, item[headItem.title])))));
    };
    const renderMoreItem = () => {
        return (React.createElement(DSTL007_Detail, { selectedId: selectedId, setSelectedId: setSelectedId }));
    };
    const handlerSelectItem = (item) => {
        const newId = selectedId === item.id ? '' : item.id;
        setSelectedId(newId);
        dispatch(changeMemuProps({ defaultOpen: newId }));
    };
    const renderEmpty = useMemo(() => {
        return React.createElement(EmptyTable, { title: 'sendSMS.noData' });
    }, []);
    return (React.createElement(SendSMSTableStyles, { "$isTablet": isTablet, "$isMobile": isMobile, className: "sms-list-table" },
        React.createElement(Table, { className: 'sms-list-001', showIndex: false, showCheckBox: false, isInifinite: false, selectedId: selectedId, page: page, showCount: count, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderTitle: renderTitle, renderItem: renderItem, renderEmpty: testData.length <= 0 && renderEmpty, renderMoreItem: isMobile && renderMoreItem, handlerSelectItem: handlerSelectItem, handlerMoreData: handlerMoreData, forceShowheader: isMobile ? false : true })));
});
