import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { CheckBox, CheckBoxElement, DropDown, } from '../../../../../common';
// 과목 리스트
const subjectList = [
    { id: 'select', title: 'common.select' },
    { id: 'korean', title: 'assignment.korean' },
    { id: 'english', title: 'assignment.english' },
    { id: 'math', title: 'assignment.math' },
    { id: 'history', title: 'assignment.history' },
    { id: 'character', title: 'assignment.character' },
];
/**
 * 출제 유형 선택 dropdown
 *  - 출제 유형 (OMR 기본형, OMR 자유형, 첨부파일, 설문) 선택
 */
export const SubjectType = React.memo(({ questionTypeId, subject, setSubject, }) => {
    const { t } = useTranslation();
    const { isDesktop, isTablet } = useGlobalState();
    const isShowSubject = useMemo(() => (questionTypeId === 'OMR' && subject ? true : false), [questionTypeId, subject]);
    if (!isShowSubject)
        return null;
    return (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label" }, t('assignment.selectSubject')),
        React.createElement("div", { className: "fext-field" },
            React.createElement(DropDown, { className: 'dropdown-popup', value: subject, styles: {
                    dropWrap: {
                        flex: 1,
                    },
                }, list: subjectList, setDropValue: setSubject }))));
});
/**
 *  화면에 보이는 과목 detail 업데이트 (subject detail)
 *  -> ex) 국어 - 공통, 선택 - 언어와매체, 선택 - 화법과 작문
 */
export const SubjectTypeDetail = React.memo(({ checkSubjectList, questionTypeId, originalSubjectId, subjectId, scoreBlank, evalList, setScoreBlank, setEvalList, }) => {
    const { t } = useTranslation();
    const [list, setList] = React.useState(evalList);
    const getSubjectDetailData = (subjectId) => {
        let data;
        switch (subjectId) {
            case 'korean':
                data = [
                    { id: '1', title: '공통', checked: false },
                    { id: '선택 - 언어와매체', title: '선택 - 언어와매체', checked: false },
                    { id: '선택 - 화법과 작문', title: '선택 - 화법과 작문', checked: false },
                ];
                break;
            case 'math':
                data = [
                    { id: '2', title: '공통', checked: false },
                    { id: '선택 - 확률과 통계', title: '선택 - 확률과 통계', checked: false },
                    { id: '선택 - 미적분', title: '선택 - 미적분', checked: false },
                    { id: '선택 - 기하', title: '선택 - 기하', checked: false },
                ];
                break;
            case 'english':
                data = [
                    { id: '듣기', title: '듣기', checked: false },
                    { id: '읽기', title: '읽기', checked: false },
                ];
                break;
            default:
                data = undefined;
        }
        return data;
    };
    const handlerSubjectList = subjectList => {
        if (subjectId === originalSubjectId) {
            const choiceSubject = subjectList &&
                subjectList.map(item => {
                    const isExist = checkSubjectList.find(i => i === item.title);
                    return { ...item, checked: isExist ? true : false };
                });
            setList(choiceSubject);
        }
        else {
            setList(subjectList &&
                subjectList.map(item => {
                    const isExist = (evalList && evalList?.find(i => i.id === item.id)?.checked) ||
                        false;
                    return {
                        ...item,
                        checked: isExist,
                    };
                }));
        }
    };
    /**
     * 과목이 변경되는 경우
     *  -> 화면에 보이는 과목 detail 업데이트 (subject detail)
     */
    React.useEffect(() => {
        if (subjectId === 'history' || subjectId === 'character') {
            setList([
                {
                    id: t(`assignment.${subjectId}`),
                    title: t(`assignment.${subjectId}`),
                    checked: true,
                },
            ]);
            return;
        }
        else {
            handlerSubjectList(getSubjectDetailData(subjectId));
        }
    }, [subjectId, originalSubjectId, checkSubjectList]);
    // (onClick) 평가 항목 업데이트
    const handlerUpdateEvalItem = (id, checked) => {
        setList(prevList => prevList.map(item => {
            if (item.id === id)
                return { ...item, checked };
            return item;
        }));
    };
    // (onClick) 전체 배점 공란 업데이트
    const updateScoreBlank = (id, checked) => {
        setScoreBlank(checked);
    };
    /**
     * 선택된 과목 상세만 들어가도록 수정
     */
    React.useEffect(() => {
        setEvalList(list);
    }, [list]);
    return (questionTypeId === 'OMR' && (React.createElement("div", { className: "field-global-001", style: { alignItems: 'flex-start' } },
        React.createElement("div", { className: "task-label" }, t('assignment.evalItem')),
        React.createElement("div", { className: "fext-field", style: { flexDirection: 'column', alignItems: 'flex-start' } },
            subjectId !== 'history' &&
                subjectId !== 'character' &&
                list &&
                list.length > 0 && (React.createElement(CheckBox, { className: "assignment-checkbox-txt", iconSize: 24, list: list, handlerCheck: handlerUpdateEvalItem })),
            React.createElement(CheckBoxElement, { id: "scoreBlank", className: "scoreBlank", title: "assignment.scoreBlank", style: { gap: 6 }, checked: scoreBlank, handlerCheck: updateScoreBlank })))));
});
