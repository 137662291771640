import { default as React, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Button, Table } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { SendMemberHeader } from './SendMemberHeader';
const SendMembersTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;

	.disable {
		background-color: var(--color-grey-c, #e6e8ed);
	}

	.name {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.dropdown {
		width: 100%;
	}

	.body-item {
		position: relative;
	}

	.dropdown-list {
		top: 48px;
	}

	.sms-choice-list {
		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			position: relative;
			max-height: 600px;
			overflow: auto;
		}
	}

	.paging {
		margin-top: 20px;
		gap: 10px;

		button {
			width: 18px;
			height: 18px;

			p {
				min-width: unset;
			}
		}
	}

	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	.table-item-wrap {
		.body-item {
			width: unset;
		}
	}

	.sms-choice-list {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		flex: 1;

		.item {
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px 0px 0px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
			}

			.grey {
				color: var(--color-grey-l, #83868a);
				font-size: var(--b4-font-size, 12px);
			}
		}
	}

	.table-header-item {
		padding-bottom: 10px;
	}

	.check-btn {
		padding: 8px 0px;
	}
`;
const tabletStyles = css `
	background: var(--color-white, #ffffff);
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	min-width: 150px;
	flex: 1;

	.sms-choice-list {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		align-self: stretch;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			position: relative;
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;

			.table-header-item {
				min-width: unset !important;
				svg {
					width: 18px;
					min-width: unset;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 16px 12px 16px;
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;

			.body-item {
				min-width: unset !important;
				.checkbox-item {
					svg {
						width: 18px;
						min-width: unset;
					}
				}
			}
		}
	}
`;
const desktopStyles = css `
	background: var(--color-white, #ffffff);
	${props => !props.$isPopup &&
    css `
			border-radius: 10px;
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px;
			padding: 24px;
		`}

	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	min-width: 215px;
	flex: 1;

	.sms-choice-list {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		align-self: stretch;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			position: relative;
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 16px 12px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}
		}
	}
`;
export const ChoiceMemberList = React.memo(({ memberSetting = "", checkedList, isNotChecked = false, memberList, noNumberData = [], setDeleteMemberList, isPopup, isNormal = true, isRegist = false, isDetail = false, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    const [count, setCount] = useState(10);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [checkList, setCheckList] = useState([]);
    const [selectedChecklistMembers, setSelectedChecklistMembers] = useState({});
    const [searchOption, setSearchOption] = useState('');
    const [hasExecuted, setHasExecuted] = useState([]);
    const [defaultCheckMembers, setDefaultCheckMembers] = useState([]);
    useEffect(() => {
        const resetCheck = () => {
            //setHasExecuted([]);
            setSelectedChecklistMembers({});
            setPreviousPage(0);
        };
        APP.eventManager.subscribe('CLEAR_CHECK_LIST', resetCheck);
        return () => {
            APP.eventManager.unsubscribe('CLEAR_CHECK_LIST', resetCheck);
        };
    }, []);
    useEffect(() => {
        if (memberList && memberList.length > 0) {
            let filteredList = memberList;
            if (searchOption) {
                filteredList = memberList.filter(i => i.data[0].toLowerCase().includes(searchOption) ||
                    i.data[1].toLowerCase().includes(searchOption) ||
                    i.data[2] && i.data[2].toLowerCase().includes(searchOption) ||
                    i.data[3] && i.data[3].toLowerCase().includes(searchOption) ||
                    i.data[4] && i.data[4].toLowerCase().includes(searchOption) ||
                    i.data[5] && i.data[5].toLowerCase().includes(searchOption));
            }
            const paginatedList = filteredList.length > 10
                ? filteredList.slice(page * count, page * count + count)
                : filteredList;
            setTestData(paginatedList);
            setTotalCount(filteredList.length);
        }
        else {
            setTestData([]);
            setTotalCount(0);
        }
    }, [memberList, searchOption, page, count]);
    // useEffect(() => {
    // 	if (memberList.length > 0) {
    // 		setPage(Math.floor(memberList.length / count - 1));
    // 	}
    // }, [memberList, count]);
    useEffect(() => {
        if (checkList.length > 0) {
            const data = testData.filter(item => checkList.includes(item.id));
            setDeleteMemberList && setDeleteMemberList(data);
        }
        else {
            setDeleteMemberList && setDeleteMemberList([]);
        }
    }, [checkList]);
    useEffect(() => {
        if (checkedList && checkedList.length > 0) {
            setDefaultCheckMembers(checkedList);
        }
    }, [checkedList]);
    // useEffect(() => {
    // 	console.log('defaultCheckMembers', defaultCheckMembers);
    // }, [defaultCheckMembers]);
    // useEffect(() => {
    // 	if (_.isEqual(hasExecuted, checkList) && page === previousPage) {
    // 		if (checkList.length === 0) {
    // 			setSelectedChecklistMembers(prevState => {
    // 				return {
    // 					...prevState,
    // 					[page]: [],
    // 				};
    // 			});
    // 		}
    // 		return;
    // 	}
    // 	if (previousPage === page && testData.length > 0) {
    // 		setSelectedChecklistMembers(prevState => {
    // 			return {
    // 				...prevState,
    // 				[page]: testData.filter(item => checkList.includes(item.id)),
    // 			};
    // 		});
    // 	}
    // 	setPreviousPage(page);
    // }, [checkList, page, testData]);
    // useEffect(() => {
    // 	if (selectedChecklistMembers) {
    // 		const selectedMembers = Object.keys(selectedChecklistMembers)
    // 			.map(index => selectedChecklistMembers[index])
    // 			.flat();
    // 		setDeleteMemberList && setDeleteMemberList(selectedMembers);
    // 		setDefaultCheckMembers(selectedChecklistMembers[page]?.map(member => member.id));
    // 	} else {
    // 		setDeleteMemberList && setDeleteMemberList([]);
    // 		setDefaultCheckMembers([]);
    // 	}
    // }, [selectedChecklistMembers]);
    const handlerMoreData = (page) => {
        setPage(page);
    };
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = [
            ...(!isMobile ? isNormal ?
                [
                    { title: 'nameId', space: 1, sort: false },
                    { title: 'phoneNumber', space: 2, sort: false },
                ] : [
                { title: 'nameId', space: 1, sort: false },
                { title: 'phoneNumber', space: 1, sort: false },
                { title: 'substitute1', space: 1, sort: false },
                { title: 'substitute2', space: 1, sort: false },
                { title: 'substitute3', space: 1, sort: false },
                { title: 'substitute4', space: 1, sort: false },
            ] : []),
        ];
        const tableReadDataKeys = isNormal ? [
            { title: 'nickname', space: 1, sort: false },
            { title: 'phone_number', space: 2, sort: false },
        ] : [
            { title: 'nickname', space: 1, sort: false },
            { title: 'phone_number', space: 1, sort: false },
            { title: 'substitute1', space: 1, sort: false },
            { title: 'substitute2', space: 1, sort: false },
            { title: 'substitute3', space: 1, sort: false },
            { title: 'substitute4', space: 1, sort: false },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isNormal, isMobile]);
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        const search = Object.values(filter);
        const searchTerm = search && search.length > 0 ? search[0] : undefined;
        setSearchOption(searchTerm);
    };
    const handlerClosePopup = () => {
        APP.eventManager.publish('TOGGLE_POPUP', undefined);
    };
    const handlerSetMembers = () => {
        const checkListMembers = memberList.filter(item => checkList.includes(item.id));
        const filteredCheckListMembers = checkListMembers.filter(item => !noNumberData.some(noNumItem => noNumItem.id === item.id));
        APP.eventManager.publish('ADD_SELECTED_MEMBERS', {
            members: filteredCheckListMembers,
            setting: memberSetting,
        });
        APP.eventManager.publish('TOGGLE_POPUP', undefined);
    };
    const renderTitle = useMemo(() => {
        return (React.createElement(SendMemberHeader, { handlerFilterList: handlerFilterList, totalTableList: memberList && memberList.length, isReceiver: true, isRegist: isRegist, isDetail: isDetail, isPopup: isPopup }));
    }, [handlerFilterList, memberList, isPopup, isRegist, isDetail]);
    // const renderItem = (item: MemberListFormat, styles: TableStyleProps) => {
    // 	const itemToUse = checkedList === undefined ?
    // 		item : [
    // 			item.nickname + (item.identifier ? `(${item.identifier})` : '(-)'),
    // 			item.phone_number.replace(/-/g, ''),
    // 		];
    // 	if (isMobile) return tableReadDataKeys.map((headItem, index) => (
    // 		<p className="overText2">
    // 			{itemToUse[index] === "" ? "-" : itemToUse[index]}
    // 			{index < tableReadDataKeys.length - 1 && " / "}
    // 		</p>
    // 	));
    // 	return tableReadDataKeys.map((headItem, index) => (
    // 		<div
    // 			key={headItem.title}
    // 			className="body-item"
    // 			style={{
    // 				flex: !isMobile && headItem.space,
    // 				...styles?.bodyItem,
    // 			}}
    // 		>
    // 			{/* <p className="overText2">{`${item[headItem.title]
    // 				? item[headItem.title]
    // 				: item.name === ''
    // 				? item.id
    // 				: item.name
    // 				} ${item['identifier']
    // 				? `(${item['identifier']})`
    // 				: item['idf']
    // 				? `(${item['idf']})`
    // 				: ''
    // 				} `}</p> */}
    // 			<p className="overText2">{itemToUse[index] === "" ? "-" : itemToUse[index]}</p>
    // 		</div>
    // 	));
    // };
    const formatPhoneNumber = (phone) => {
        if (!phone)
            return "-";
        return phone.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3");
    };
    const renderItem = (item, styles) => {
        if (isMobile)
            return tableReadDataKeys.map((headItem, index) => (React.createElement("p", { className: "overText2" },
                index === 1 ? formatPhoneNumber(item.data[index]) : item.data[index] || "-",
                index < tableReadDataKeys.length - 1 && " / ")));
        return tableReadDataKeys.map((headItem, index) => (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                flex: !isMobile && headItem.space,
                ...styles?.bodyItem,
            } },
            React.createElement("p", { className: "overText2" }, index === 1 ? formatPhoneNumber(item.data[index]) : item.data[index] || "-"))));
    };
    return (React.createElement(SendMembersTableStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isPopup": isPopup, className: "user-list" },
        renderTitle,
        React.createElement(Table, { className: "sms-choice-list", forceShowheader: true, showCheckBox: isNotChecked ? false : true, defaultCheckList: defaultCheckMembers, disableIdList: noNumberData.map(item => item.id), isInifinite: false, data: testData, totalCount: totalCount, page: page, showCount: count, sortDefault: "id", headTitleList: headTitleList, renderItem: renderItem, handlerCheckList: setCheckList, handlerMoreData: handlerMoreData, isKeepChecked: true, isSMS: true, renderGuidItem: isNotChecked ? undefined : React.createElement(React.Fragment, null) }),
        isPopup && !isNotChecked && (React.createElement("div", { className: "btn-container", style: { width: "100%", justifyContent: "center" } },
            React.createElement(Button, { className: "btn_line_grey xmd", text: t('common.cancel'), onClick: handlerClosePopup }),
            React.createElement(Button, { className: "btn_default xmd", text: t('common.regist'), onClick: handlerSetMembers })))));
});
