import _ from 'lodash';
import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Colors } from '../../../../Colors';
import { Button, CheckBox, Icon, InputElement } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { postModifySendSMS, postRegistSendSMS, useSendSMSList, useSMSReceiversList, } from '../../../../server/sms';
import { DeletePopUpLayout } from '../../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../../base/popup';
import { updateMenu } from '../../../menu/action';
import { DATE_CHECK_LIST, SMS_TYPE_CHECK_LIST } from '../constant';
import { DetailTitleInfo } from '../../../base/layout';
import { MemberList } from './MemberList';
const SendSMSContentStyled = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;
	gap: ${props => (props.$isDesktop ? '40px' : '12px')};
	overflow: auto;

	.divider {
		background: var(--color-grey-c, #e6e8ed);
		align-self: stretch;
		flex-shrink: 0;
		height: 1px;
	}

	.sms-preview {
		display: flex;
		flex-direction: column;
		padding: 26px;
		width: 25%;
		height: 100%;
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 1px;
		min-width: 260px;
	}

	.sms-preview-content {
		word-break: break-word;	
		flex: 1;
		width: 100%;	
    	min-height: 100px;
	    border-radius: 4px;
    	border-style: solid;
    	border-color: var(--color-grey-w, #b6bac0);
    	border-width: 1px;
	    white-space: pre-wrap;
		padding: 9px;
    	text-align: left;
    	font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
    	font-size: var(--b4-font-size, 14px);
    	line-height: 150%;
    	font-weight: var(--b4-font-weight, 400);
		span {
			text-align: left;
    		font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
    		font-size: var(--b4-font-size, 14px);
    		line-height: 150%;
    		font-weight: var(--b4-font-weight, 400);
		}
	}

	.sms-regist {
		${props => (props.$isDesktop ? `
			display: flex;
			flex-direction: column;
			padding: 26px;	
			gap: 24px;
			width: 75%;
			height: 100%;
			border-radius: 10px;
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px;
			overflow: auto;
			` : `
			display: flex;
			flex-direction: column;
			gap: 12px;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			`)}
	}

	.sms-setting-content {
		${props => (props.$isMobile && "height: 100%;")}
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.sms-setting {
		display: flex;
		flex-direction: ${props => (!props.$isMobile ? 'row' : 'column')};
		gap: ${props => (props.$isDesktop ? '32px' : '12px')};
		height: ${props => (props.$isDesktop ? '40px' : 'fit-content')};
		align-items: ${props => (!props.$isMobile ? 'center' : 'flex-start')};
		${props => (props.$isTablet && `
			flex-direction: column;
			height: fit-content;
			gap: 4px;
			align-items: flex-start;	
		`)}
	}

	.sms-substitute-wrap {
		display: flex;
		flex-direction: ${props => (!props.$isMobile ? 'row' : 'column')};
		gap: 12px;
		flex-shrink: 0;
		align-items: ${props => (!props.$isMobile ? 'center' : 'flex-start')};
	}

	.sms-type {
		display: flex;
		flex-direction: row;
		gap: 12px;
		flex-shrink: 0;
		align-items: center;
		min-height: ${props => (!props.$isMobile ? '45px' : 'fit-content')};
	}

	.sms-time-wrap {
		display: flex;
		flex-direction: row;
		gap: 44px;
		flex-shrink: 0;
		align-items: center;
	}

	.sms-time {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;

		input {
    		border-radius: 4px;
    		border-style: solid;
    		border-color: var(--color-grey-w, #b6bac0) !important;
    		border-width: 1px;
    		padding: ${props => (!props.$isMobile ? '7px' : '5px')};
    		width: ${props => (!props.$isMobile ? '118px' : '110px')};
			${props => (props.$isMobile && 'height: 32px;')}
    		box-sizing: border-box;
    		color: var(--color-black, #101010);
    		text-align: left;
    		font-family: var(--b4-font-family, "Noto Sans KR", sans-serif);
		    font-size: var(--b4-font-size, 14px);
    		line-height: var(--b4-line-height, 22px);
		    letter-spacing: var(--b4-letter-spacing, -0.02em);
    		font-weight: var(--b4-font-weight, 400);
    		position: relative;
    		text-overflow: ellipsis;
    		overflow: hidden;
    		white-space: nowrap;
    		appearance: none;
    		-webkit-appearance: none;
    		-moz-appearance: none;
		}

		${props => (props.$isTablet && `
			height: 40px;
		`)}
	}

	.substitute-list {
		display: flex;
		flex-direction: row;
		gap: 12px;
		flex-wrap: wrap;
	}
	
	.icon-wrap {
		width: 122px;
		height: 122px;
		border: solid 1px ${Colors.w_grey};
		border-radius: 100%;
	}

	.select-sms {
		font-size: 30px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: -0.02em;
		color: #7691B6;
	}

	.btn-recipient-setting {
		background: var(--color-white, #ffffff);
		border-radius: 100px;
		border-style: solid;
		border-color: var(--tur, #3ab2bf);
		border-width: 1px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;

		p {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			${props => !props.$isDesktop
    ? `
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
			`
    : `
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);`}
			padding-bottom: 3px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		${props => !props.$isMobile
    ? `
					position: absolute;
					right: 24px;
					padding: 4px 18px 4px 18px;
					z-index: 1;	
					gap: 8px;
				`
    : `  
					padding: 4px 10px 4px 10px;
					gap: 0px;
				`}
	}

	span,
	.overText {
		flex-shrink: 0;
		position: relative;
		text-align: left;

		${props => props.$isDesktop
    ? `
					font-size: var(--b3-font-size, 16px);
					line-height: var(--b3-line-height, 32px);
					letter-spacing: var(--b3-letter-spacing, -0.02em);
					font-weight: var(--b3-font-weight, 400);
				  `
    : `
					font-size: 14px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
				  `}
	}

	.sms-wrap {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: row;
		flex: 1;
		gap: ${props => (!props.$isMobile ? '32px' : '20px')};
		padding: ${props => (props.$isDesktop ? '0px' : '20px')};
		overflow: auto;
	}

	.preview-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${props => (!props.$isMobile ? '12px' : '10px')};
	}

	.send-wrap {
		min-height: ${props => (!props.$isMobile ? '40px' : '70px')};
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${props => (!props.$isMobile ? '12px' : '10px')};
		align-items: flex-start;
	}

	textarea {
		&.focus {
			outline: 2px solid transparent;
			outline-offset: 2px;
			box-shadow: 0 0 5px $tur; // 흐림 효과 추가
		}

		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		width: 100%;
		resize: none;
		overflow: hidden;
		padding: 9px;

		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: 150%;
		font-weight: var(--b4-font-weight, 400);
	}

	.sms-title {
		max-height: 40px;
		min-height: 40px !important;
	}

	.sms-content-wrap {
		display: flex;
		width: 100%;
		flex: 1;
		height: 158px;
	}
	
	.editable {
		min-height: 100px;
		flex: 1;
	}

	.sms-content {
		min-height: 100px;
		${props => props.$isMobile && 'height: 100% !important;'}
		flex: 1;
	}

	.content-info {
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: center;
		gap: 6px;
	}

	.substitute-empty {
		height: 36px;
		width: 100%;
		line-height: 115%;
	}

	.substitute-word {
		height: 36px;
    	width: fit-content;
    	padding: 0;
    	border-color: ${Colors.dark_tur};
		color: ${Colors.light_tur};
		background-color: #F5F9F9;
    	align-content: center;
    	text-align: center;
		border-radius: 6px;
		cursor: pointer;

		.overText {
			min-width: 0px;
		}
	}

	.open-member-list {
		display: flex;
		flex-direction: column;
		height: fit-content;
		width: 100%;
	}

	.xmd {
		p {
			font-size: 14px;
			line-height: 20px;
			display: flex;
			justify-content: center;
		}
	}

	.message-text {
		align-content: center;
		flex-shrink: 0;
		min-height: ${props => (props.$isDesktop ? '32px' : props.$isTablet ? '25px' : '20px')};
	}

	.message-length {
		align-content: center;
		text-align: right;
		font-size: 16px;
		line-height: 32px;
		color: var(--color-grey-b, #7e8289);
	}

	.btn-container {
		margin: 0;
	}

	.send-type {
		span {
			font-size: ${props => (props.$isDesktop ? '14px' : '12px')};
		}
	}

	${props => props.$isMobile &&
    `
			.mobile-full {
				border-radius: 6px;
				p {
					justify-content: center;
				}
			}

			.btn-container {
				width: 100%;
			}
		`}

	.member-list-popup{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.popup-body {
		gap: 16px;
	}

	.popup-header-member-list {
		padding-bottom: 12px;
    	width: 100%;
    	display: flex;
    	justify-content: space-between;
    	align-items: center;
    	box-sizing: border-box;

		border-bottom: 1px solid #e6e8ed;

		.overText {
			font-size: 16px;
			line-height: 24px;
			font-weight: 700;
		}
	}
	
	.popup-content {
		overflow-x: hidden;
		overflow-y: auto;
	}
`;
export const substitutes = [
    '이름',
    '번호',
    '항목1',
    '항목2',
    '항목3',
    '항목4'
];
export const SendSMSRegist = React.memo(({ selectedId, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [isNormal, setIsNormal] = React.useState(true); //발송유형(일반/치환) checkBox
    const [isImmediately, setIsImmediately] = React.useState(true); //발송일자 checkBox
    const [isFixNumber, setIsFixNumber] = React.useState(true); //발신번호 checkBox
    const [startDate, setStartDate] = useState(''); // 발송일
    const [startTime, setStartTime] = useState(''); // 발송시간
    const [directNumber, setDirectNumber] = useState(''); // 발신번호
    const [title, setTitle] = useState(''); // 메시지 제목
    const [message, setMessage] = useState(''); // 발송 메시지
    const [preview, setPreview] = useState(''); // 미리보기 메시지
    const [openMemberList, setOpenMemberList] = useState(false); // 수신자 리스트 팝업
    const [receiverMemberList, setReceiverMemberList] = useState([]); // 수신자 리스트
    const [memberSetting, setMemberSetting] = React.useState('everyStudent'); //수신자 설정(수강생전체/한 건씩 추가/엑셀 업로드) checkBox
    const [originalStudentData, setOriginalStudentData] = useState([]); // 수강생(전체) 원본 데이터터
    const [everyStudentData, setEveryStudentData] = useState([]); // 수강생(전체) 데이터
    const [oneByOneData, setOneByOneData] = useState([]); // 한 건씩 추가 데이터
    const [excelUploadData, setExcelUploadData] = useState([]); // 엑셀 업로드 데이터
    const previewDivRef = React.useRef(null); // Use ref to reference the div
    // 한글 2바이트, 영문 1바이트로 계산
    const getCoolsmsByteLength = (message) => [...message].reduce((bytes, char) => bytes + (char.charCodeAt(0) <= 127 ? 1 : 2), 0);
    const messageBytes = getCoolsmsByteLength(message);
    const isSMSLength = messageBytes <= 90;
    const messageLength = isSMSLength ? `(${messageBytes}/90)` : "MMS";
    // 현재 시간
    const now = useMemo(() => moment(), []);
    const registSendSMSMutation = useMutation(postRegistSendSMS);
    const modifySendSMSMutation = useMutation(postModifySendSMS);
    const { data, refetch } = useSendSMSList({
        id: selectedId,
    });
    const { data: receiversData, refetch: receiversRefetch } = useSMSReceiversList({
        id: selectedId,
    });
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    const refetchReceiversList = throttle(() => receiversRefetch(), 1000, { leading: true, trailing: false });
    const cleanup = () => {
        setIsImmediately(true);
        setStartDate(moment().format('YYYY-MM-DD'));
        setStartTime(moment().format('HH:mm'));
        setIsFixNumber(true);
        setDirectNumber('');
        setTitle('');
        setMessage('');
        setPreview('');
        setReceiverMemberList([]);
        // setOpenMemberList(false);
    };
    useEffect(() => {
        if (selectedId) {
            refetchList();
            refetchReceiversList();
        }
        else
            cleanup();
    }, [selectedId]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            if (items && items.length >= 1) {
                const item = items[0];
                setIsImmediately(item.send_time === '' ? true : false);
                setStartDate(moment(item.send_time).format('YYYY-MM-DD'));
                setStartTime(moment(item.send_time).format('HH:mm'));
                setIsFixNumber(item.phone_number.includes('*') ? true : false);
                setDirectNumber(item.phone_number.includes('*') ? '' : item.phone_number);
                setTitle(item.title);
                setMessage(item.content);
                setIsNormal(item.type === 'normal' ? true : false);
                const newReceiverMemberList = !_.isEqual(receiverMemberList, item.receivers) &&
                    receiverMemberList.length !== 0
                    ? receiverMemberList
                    : item.receivers;
                // @ts-ignore
                setReceiverMemberList(isMobile ? newReceiverMemberList : item.receivers);
                return;
            }
        }
        !isMobile && cleanup();
    }, [data]);
    useEffect(() => {
        if (receiversData) {
            const { receivers, receivers_type } = receiversData;
            const memberSetting = receivers_type === 0 ? 'everyStudent' : 'oneByOne';
            setMemberSetting(memberSetting);
            if (receivers && receivers.length >= 1) {
                const simpleItems = receivers.map(receiver => ({
                    id: receiver[1],
                    data: receiver,
                }));
                setReceiverMemberList(simpleItems);
                if (memberSetting === 'everyStudent') {
                    setEveryStudentData(simpleItems);
                }
                else if (memberSetting === 'oneByOne') {
                    setOneByOneData(simpleItems);
                }
                else if (memberSetting === 'excelUpload') {
                    setExcelUploadData(simpleItems);
                }
            }
        }
    }, [receiversData]);
    // 치환문자 리스트의 너비를 동적으로 조절
    useEffect(() => {
        if (isNormal)
            return;
        if (substitutes.length > 0) {
            substitutes.forEach((item, index) => {
                const textarea = document.querySelectorAll(".substitute-word")[index];
                if (textarea) {
                    const context = document.createElement("canvas").getContext("2d");
                    if (context) {
                        context.font = getComputedStyle(textarea).font;
                        const textWidth = context.measureText(item).width;
                        textarea.style.width = `${textWidth + 20}px`;
                    }
                }
            });
        }
    }, [isNormal, substitutes]);
    const handlerPreview = () => {
        const previewTitle = title.length > 0 ? `<span style="font-weight: bold;">${title}</span>\n` : '';
        setPreview(previewTitle + message);
    };
    useEffect(() => {
        if (isNormal)
            return;
    }, [message, substitutes, isNormal]);
    // 미리보기에서 치환문자만 하이라이트
    useEffect(() => {
        if (isMobile)
            return;
        const previewDiv = previewDivRef.current;
        if (!previewDiv)
            return;
        if (!preview) {
            previewDiv.innerHTML = "<span style='color: gray;'>내용 없음</span>";
            return;
        }
        if (isNormal) {
            previewDiv.innerHTML = preview;
            return;
        }
        const highlightedText = preview.replace(/#\{(.*?)\}/g, (match, word) => {
            return substitutes.includes(word)
                ? `<span style="color: #8268d9;">${match}</span>`
                : match;
        });
        previewDiv.innerHTML = highlightedText;
    }, [preview, substitutes, isNormal, isMobile]);
    // useEffect(() => {
    // 	if (!isMobile) return;
    // 	if (!openMemberList) return;
    // 	APP.eventManager.publish(TOGGLE_POPUP, {
    // 		component: MemberList,
    // 		componentProps: {
    // 			openMemberList: openMemberList,
    // 			isNormal: isNormal,
    // 			substitutes: substitutes,
    // 			receiverMemberList: receiverMemberList,
    // 			setReceiverMemberList: setReceiverMemberList,
    // 			memberSetting: memberSetting,
    // 			originalStudentData: originalStudentData,
    // 			everyStudentData: everyStudentData,
    // 			oneByOneData: oneByOneData,
    // 			excelUploadData: excelUploadData,
    // 			setMemberSetting: setMemberSetting,
    // 			setOriginalStudentData: setOriginalStudentData,
    // 			setEveryStudentData: setEveryStudentData,
    // 			setOneByOneData: setOneByOneData,
    // 			setExcelUploadData: setExcelUploadData,
    // 			handlerClose: () => setOpenMemberList(false),
    // 		},
    // 		width: 639,
    // 		title: t('sendSMS.selectMember'),
    // 		isFit: true,
    // 	});
    // }, [receiverMemberList, isMobile, openMemberList, isNormal, substitutes]);
    const addSubstituteWord = (word) => {
        const inputElement = document.querySelector('.sms-content');
        if (!inputElement)
            return;
        const { selectionStart, selectionEnd, value } = inputElement;
        const newValue = value.slice(0, selectionStart) + `#{${word}}` + value.slice(selectionEnd);
        setMessage(newValue);
        setTimeout(() => {
            inputElement.setSelectionRange(selectionStart + `#{${word}}`.length, selectionStart + `#{${word}}`.length);
            inputElement.focus();
        }, 0);
    };
    // const deleteSubstituteWord = () => {
    // 	console.log('substitues', substitutes);
    // 	setMessage(prev => {
    // 		const cursorPosition = prev.length;
    // 		const textBeforeCursor = prev.slice(0, cursorPosition);
    // 		const match = textBeforeCursor.match(/(#{[^}]*})$/);
    // 		if (match) {
    // 			const matchedText = match[0];
    // 			return prev.slice(0, -matchedText.length + 1);
    // 		}
    // 		return prev;
    // 	});
    // };
    const deleteSubstituteWord = () => {
        const textarea = document.getElementById("sms_content");
        if (!textarea)
            return;
        const cursorPosition = textarea.selectionStart;
        setMessage(prev => {
            let newCursorPos = cursorPosition - 1; // Default backspace behavior
            const regex = /#{[^}]+}/g;
            let match;
            let targetMatch = null;
            let targetStart = -1;
            let targetEnd = -1;
            while ((match = regex.exec(prev)) !== null) {
                const startIdx = match.index;
                const endIdx = startIdx + match[0].length;
                // If the cursor is inside the #{word} range
                if (cursorPosition > startIdx && cursorPosition <= endIdx) {
                    targetMatch = match[0];
                    targetStart = startIdx;
                    targetEnd = endIdx;
                    break; // Stop at the first match found
                }
            }
            let newMessage = prev;
            // If a match was found and the word inside is in substitutes, remove it
            if (targetMatch) {
                const wordInside = targetMatch.slice(2, -1);
                if (substitutes.includes(wordInside)) {
                    newMessage = prev.slice(0, targetStart) + prev.slice(targetEnd);
                    newCursorPos = targetStart; // Move cursor to where #{word} was deleted
                }
            }
            else {
                // If no match, perform a normal backspace
                newMessage = prev.slice(0, cursorPosition - 1) + prev.slice(cursorPosition);
            }
            // Delay setting cursor position to ensure state updates first
            setTimeout(() => {
                textarea.selectionStart = textarea.selectionEnd = newCursorPos;
            }, 0);
            return newMessage;
        });
    };
    const extractSubstituteList = (message, substitutes) => {
        const matches = [...message.matchAll(/#\{(.*?)\}/g)].map(m => m[1]);
        return substitutes.filter(sub => matches.includes(sub));
    };
    const validateSubstitutes = (message, substitutes) => {
        const matches = [...message.matchAll(/#\{(.*?)\}/g)].map(m => m[1]);
        const hasInvalidWords = matches.some(word => !substitutes.includes(word));
        return !hasInvalidWords;
    };
    const handlerCancel = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: t(`supportRoom.answerPopUpcancel`),
                previosPage: () => {
                    if (isDesktop) {
                        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                            menuDetail: 'list',
                            id: undefined,
                        });
                    }
                    else {
                        dispatch(updateMenu(undefined, { menu: 'sendSMS', detail: 'list' }));
                    }
                    APP.eventManager.publish(TOGGLE_POPUP, null);
                },
            },
            width: 639,
            title: t(`common.menuMove`),
            isFit: true,
        });
    };
    const validatePhoneNumber = phoneNumber => {
        // 한국 전화번호 형식 검증: 010-1234-5678, 01012345678, 011-123-4567, 016-123-4567 등
        const phoneRegex = /^(01[016789]-?\d{3,4}-?\d{4}|070-\d{3,4}-\d{4}|0[2-9]{1}\d{1,2}-?\d{3,4}-?\d{4})$/;
        return phoneRegex.test(phoneNumber);
    };
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    // (onClick) 보내기
    const handlerSend = async () => {
        const format = receiverMemberList.map(member => isNormal ? [
            member.data[0],
            member.data[1],
        ] : [
            member.data[0],
            member.data[1],
            member.data[2] || "",
            member.data[3] || "",
            member.data[4] || "",
            member.data[5] || ""
        ]);
        const extractList = extractSubstituteList(message, substitutes);
        const isValidFormat = receiverMemberList.every(member => {
            const nickname = member.data[0];
            const phoneNumber = member.data[1];
            const hasMandatoryFields = nickname && phoneNumber;
            const hasAllSubstitutes = extractList.every(substitute => {
                if (substitute === "항목1")
                    return member.data[2] !== '-' && member.data[2]; // substitute1
                if (substitute === "항목2")
                    return member.data[3] !== '-' && member.data[3]; // substitute2
                if (substitute === "항목3")
                    return member.data[4] !== '-' && member.data[4]; // substitute3
                if (substitute === "항목4")
                    return member.data[5] !== '-' && member.data[5]; // substitute4
                return true;
            });
            return hasMandatoryFields && hasAllSubstitutes;
        });
        if (!isImmediately && moment(`${startDate} ${startTime}`).isBefore(now)) {
            return alert(t('sendSMS.retypeDate'));
        }
        if (receiverMemberList.length === 0) {
            return alert(t('sendSMS.selectMemberPlease'));
        }
        if (!isFixNumber && !validatePhoneNumber(directNumber)) {
            return alert(t('sendSMS.retypeNumber'));
        }
        if (!isSMSLength && title.length === 0) {
            return alert(t('sendSMS.noTitle'));
        }
        if (message.trim().length === 0) {
            return alert(t('sendSMS.retypeMessage'));
        }
        if (!isNormal && !validateSubstitutes(message, substitutes)) {
            return alert(t('sendSMS.retypeSubstitute'));
        }
        if (!isNormal && !isValidFormat) {
            return alert(t('sendSMS.missingSubstitute'));
        }
        if (format.some(item => !item[1] || item[1].length < 10)) {
            return alert(t('sendSMS.zeroPhoneNumber'));
        }
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: selectedId ? t('sendSMS.editSMSPopUp') : t('sendSMS.submitSMSPopUp'),
                onRegist: handlerSubmit,
            },
            width: 639,
            title: selectedId ? t('sendSMS.editSMS') : t('sendSMS.submitSMS'),
            isFit: true,
        });
    };
    const handlerSubmit = async () => {
        const format = receiverMemberList.map(member => isNormal ? [
            member.data[0],
            member.data[1],
        ] : [
            member.data[0],
            member.data[1],
            member.data[2] || "",
            member.data[3] || "",
            member.data[4] || "",
            member.data[5] || ""
        ]);
        if (selectedId) {
            try {
                APP.eventManager.publish('SHOW_LOADING', true);
                const response = await modifySendSMSMutation.mutateAsync({
                    id: selectedId,
                    props: {
                        title: title,
                        content: message,
                        phone_number: isFixNumber ? '' : directNumber,
                        // receivers: receiverMemberList.map(item => item.id),
                        receivers: format,
                        receivers_type: memberSetting === 'everyStudent' ? 0 : memberSetting === 'oneByOne' ? 1 : 2,
                        send_time: isImmediately
                            ? null
                            : moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                    },
                });
                if (response.result.skip > 0) {
                    alert(t('sendSMS.sendSkip', { skip: response.result.skip }));
                }
                APP.eventManager.publish('UPDATE_SEND_SMS');
                // setSelectedId('');
                refetchList();
                cleanup();
                if (isMobile) {
                    dispatch(updateMenu(undefined, { menu: 'sendSMS', detail: 'list' }));
                }
                else {
                    APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                        menuDetail: 'list',
                        id: undefined,
                    });
                }
            }
            catch (error) {
                console.error('Failed to post modifySendSMS:', error);
                alert(error);
            }
            finally {
                APP.eventManager.publish('SHOW_LOADING', false);
                closePopup();
            }
        }
        else {
            try {
                APP.eventManager.publish('SHOW_LOADING', true);
                const response = await registSendSMSMutation.mutateAsync({
                    title: title,
                    content: message,
                    phone_number: isFixNumber ? '' : directNumber,
                    // receivers: receiverMemberList.map(item => item.id),
                    receivers: format,
                    receivers_type: memberSetting === 'everyStudent' ? 0 : memberSetting === 'oneByOne' ? 1 : 2,
                    send_time: isImmediately
                        ? null
                        : moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                });
                if (response.result.skip > 0) {
                    alert(t('sendSMS.sendSkip', { skip: response.result.skip }));
                }
                APP.eventManager.publish('UPDATE_SEND_SMS');
                setReceiverMemberList([]);
                cleanup();
                if (isMobile) {
                    dispatch(updateMenu(undefined, { menu: 'sendSMS', detail: 'list' }));
                }
                else {
                    APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                        menuDetail: 'list',
                        id: undefined,
                    });
                }
            }
            catch (error) {
                console.error('Failed to post registSendSMS:', error);
                alert(error);
            }
            finally {
                APP.eventManager.publish('SHOW_LOADING', false);
                closePopup();
            }
        }
    };
    const handlerOpenMemberList = () => {
        setOpenMemberList(!openMemberList);
        // APP.eventManager.publish(TOGGLE_POPUP, {
        // 	component: MemberList,
        // 	componentProps: {
        // 		openMemberList: openMemberList,
        // 		isNormal: isNormal,
        // 		substitutes: substitutes,
        // 		receiverMemberList: receiverMemberList,
        // 		setReceiverMemberList: setReceiverMemberList,
        // 		memberSetting: memberSetting,
        // 		originalStudentData: originalStudentData,
        // 		everyStudentData: everyStudentData,
        // 		oneByOneData: oneByOneData,
        // 		excelUploadData: excelUploadData,
        // 		setMemberSetting: setMemberSetting,
        // 		setOriginalStudentData: setOriginalStudentData,
        // 		setEveryStudentData: setEveryStudentData,
        // 		setOneByOneData: setOneByOneData,
        // 		setExcelUploadData: setExcelUploadData,
        // 		handlerClose: () => setOpenMemberList(false),
        // 	},
        // 	width: 639,
        // 	title: t('sendSMS.selectMember'),
        // 	isFit: true,
        // });
    };
    const handlerPreviousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'list',
            id: undefined,
        });
    };
    const renderTitleInfo = (React.createElement(DetailTitleInfo, { title: t('sendSMS.sendSMS'), previousPage: handlerPreviousPage }));
    // const [editor] = useState(() => withReact(createEditor()));
    // const initialValue = [
    // 	{
    // 		type: 'paragraph',
    // 		children: [{ text: 'A line of text in a paragraph.' }],
    // 	},
    // ]
    return (React.createElement(SendSMSContentStyled, { className: "send-sms-content", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        isDesktop && renderTitleInfo,
        React.createElement("div", { className: "sms-wrap" },
            !isMobile && (React.createElement("div", { className: "sms-preview" },
                React.createElement("div", { className: "preview-wrap", style: { flex: '1' } },
                    React.createElement("p", { className: "overText message-text" }, t('sendSMS.messagePreview')),
                    React.createElement("div", { ref: previewDivRef, className: "sms-preview-content" })))),
            React.createElement("div", { className: "sms-regist" },
                isMobile && (React.createElement(Button, { className: "btn-recipient-setting", text: `수신자 (${receiverMemberList?.length > 0
                        ? receiverMemberList.length.toString().padStart(2, '0')
                        : '00'})명`, option: {
                        buttonAfterIcon: {
                            show: true,
                            // className: isMobile
                            // 	? ''
                            // 	: openMemberList
                            // 		? 'arrow-left'
                            // 		: 'arrow-right',
                            name: isMobile ? 'plus' : 'twoarrow',
                            size: isDesktop ? 22 : 12,
                            color: Colors.tur,
                        },
                    }, onClick: handlerOpenMemberList })),
                React.createElement("div", { className: "sms-setting-content" },
                    React.createElement("div", { className: "sms-setting" },
                        React.createElement("div", { className: "sms-type" },
                            React.createElement("p", { className: "overText" }, t('sendSMS.sendType')),
                            React.createElement(CheckBox, { iconSize: isDesktop ? 24 : 20, isRadioButton: true, list: SMS_TYPE_CHECK_LIST, defaultCheckId: isNormal ? 'normal' : 'substitute', handlerCheck: (id, checked) => {
                                    setIsNormal(id === 'normal');
                                } })),
                        React.createElement("div", { className: "sms-time-wrap" },
                            React.createElement("div", { className: "sms-time" },
                                React.createElement("p", { className: "overText" }, t('sendSMS.sendDate')),
                                React.createElement("div", { className: "sms-time", style: {
                                        flexDirection: !isMobile ? 'row' : 'column',
                                        alignItems: !isMobile ? 'center' : 'flex-start'
                                    } },
                                    React.createElement(CheckBox, { iconSize: isDesktop ? 24 : 20, isRadioButton: true, list: DATE_CHECK_LIST, defaultCheckId: isImmediately ? 'now' : 'reservation', handlerCheck: (id, checked) => {
                                            setIsImmediately(id === 'now');
                                        } }),
                                    !isImmediately && (React.createElement("div", { className: "sms-time" },
                                        React.createElement("div", { className: `fext-field ${isMobile ? 'mobile-date-wrap' : ''}` },
                                            React.createElement(InputElement, { type: "date", className: "input_send", value: startDate, onChange: setStartDate, min: now.format('YYYY-MM-DD') })),
                                        React.createElement("div", { className: `fext-field ${isMobile ? 'mobile-date-wrap' : ''}` },
                                            React.createElement(InputElement, { type: "time", className: "input_send", value: startTime, onChange: setStartTime })))))))),
                    React.createElement("div", { className: "send-wrap" },
                        React.createElement("p", { className: "overText message-text" }, t('sendSMS.messageTitle')),
                        React.createElement(InputElement, { className: "sms-title", type: "text", textarea: true, value: title, onChange: value => {
                                if (value.length <= 20) {
                                    setTitle(value);
                                }
                            }, placeholder: t('sendSMS.messageTitle') })),
                    React.createElement("div", { className: "send-wrap", style: isMobile ? { height: '100%', minHeight: '147px' } : {} },
                        React.createElement("div", { className: "content-info" },
                            React.createElement("p", { className: "overText message-text" }, t('sendSMS.messageContent')),
                            React.createElement("p", { className: "message-length" }, messageLength)),
                        React.createElement("div", { className: "sms-content-wrap" },
                            React.createElement(InputElement, { id: "sms_content", className: "sms-content", type: "text", textarea: true, value: message, onBackspace: deleteSubstituteWord, 
                                // onBackspace={true}
                                onChange: value => {
                                    if (value.length < 1000) {
                                        setMessage(value);
                                    }
                                }, 
                                // style={"height: 158px"}
                                placeholder: t('sendSMS.messageContent') }))),
                    !isNormal && (React.createElement("div", { className: "sms-substitute-wrap" },
                        React.createElement("p", { className: "overText message-text" }, t('sendSMS.substituteCharacter')),
                        React.createElement("div", { className: "substitute-list" }, substitutes.map((item, index) => (React.createElement(Button, { key: index, className: "btn_line_tur substitute-word", text: item, onClick: () => addSubstituteWord(item), style: { color: Colors.tur } }))))))),
                !isMobile && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "divider" }),
                    React.createElement(MemberList, { isNormal: isNormal, substitutes: substitutes, receiverMemberList: receiverMemberList, setReceiverMemberList: setReceiverMemberList, memberSetting: memberSetting, originalStudentData: originalStudentData, everyStudentData: everyStudentData, oneByOneData: oneByOneData, excelUploadData: excelUploadData, setMemberSetting: setMemberSetting, setOriginalStudentData: setOriginalStudentData, setEveryStudentData: setEveryStudentData, setOneByOneData: setOneByOneData, setExcelUploadData: setExcelUploadData }))),
                isMobile ? (React.createElement("div", { className: "btn-container" },
                    React.createElement(Button, { className: `btn_default ${isMobile ? 'mobile-full' : 'xmd'}`, text: "\uBCF4\uB0B4\uAE30", onClick: handlerSend }))) : (React.createElement("div", { className: "btn-container", style: { justifyContent: 'space-between' } },
                    React.createElement(Button, { className: `btn_line_light_grey ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('sendSMS.preview'), onClick: handlerPreview }),
                    React.createElement("div", { className: "btn-container" },
                        React.createElement(Button, { className: `btn_white ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('common.cancel'), onClick: handlerCancel }),
                        React.createElement(Button, { className: `btn_default ${isMobile ? 'mobile-full' : 'xmd'}`, text: "\uBCF4\uB0B4\uAE30", onClick: handlerSend })))))),
        isMobile && (React.createElement("div", { className: "popup", style: { display: openMemberList ? 'flex' : 'none' } },
            React.createElement("div", { className: "popup-wrapper", style: { width: "90%" } },
                React.createElement("div", { className: "popup-body" },
                    React.createElement("div", { className: "popup-header-member-list" },
                        React.createElement("p", { className: "overText" }, t('sendSMS.selectMember')),
                        React.createElement("div", { onClick: () => setOpenMemberList(!openMemberList), style: { cursor: 'pointer' } },
                            React.createElement(Icon, { icon: "close", size: 24, fill: Colors.light_grey }))),
                    React.createElement("div", { className: "popup-content" },
                        React.createElement(MemberList, { isNormal: isNormal, substitutes: substitutes, receiverMemberList: receiverMemberList, setReceiverMemberList: setReceiverMemberList, memberSetting: memberSetting, originalStudentData: originalStudentData, everyStudentData: everyStudentData, oneByOneData: oneByOneData, excelUploadData: excelUploadData, setMemberSetting: setMemberSetting, setOriginalStudentData: setOriginalStudentData, setEveryStudentData: setEveryStudentData, setOneByOneData: setOneByOneData, setExcelUploadData: setExcelUploadData }))))))));
});
