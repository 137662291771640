export const SMS_TYPE_CHECK_LIST = [
    { id: 'normal', title: 'sendSMS.normal', checked: true },
    { id: 'substitute', title: 'sendSMS.substitute', checked: false },
];
export const DATE_CHECK_LIST = [
    { id: 'now', title: 'sendSMS.now', checked: true },
    { id: 'reservation', title: 'sendSMS.reservation', checked: false },
];
export const SMS_MEMBER_CHECK_LIST = [
    { id: 'everyStudent', title: 'sendSMS.everyStudent', checked: true },
    { id: 'oneByOne', title: 'sendSMS.oneByOne', checked: false },
    { id: 'excelUpload', title: 'sendSMS.excelUpload', checked: false },
];
export const NUMBER_CHECK_LIST = [
    { id: 'fix', title: 'sendSMS.fix', checked: true },
    { id: 'direct', title: 'sendSMS.direct', checked: false },
];
export const SMS_STATUS = {
    CREATED: 'created',
    SENT: 'sent',
    FAILED: 'failed',
};
// export const SMS_STATUS = [
// 	{ id: 'create', title: 'sendSMS.create' },
// 	{ id: 'send', title: 'sendSMS.send' },
// 	{ id: 'failed', title: 'sendSMS.failed' },
// ];
