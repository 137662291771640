import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useGlobalState, useHistoryStore } from '../../GlobalStateProvider';
import { MobileTabLayout } from '../base/layout';
import { DSSL001_Detail } from '../DSS';
import { DSSL007_List } from '../DSS/DSSL007/DSSL007_List';
import { DSTL001_Detail, DSTL001_List, DSTP001_Regist } from '../DST/DSTL001';
import { DSTL001_Submit_Detail } from '../DST/DSTL001/DSTL001_Submit_Detail';
import { DSTL001_Submit_View } from '../DST/DSTL001/DSTL001_Submit_View';
import { DSTL006_Detail } from '../DST/DSTL006/DSTL006_Detail';
import { DSTL006_List } from '../DST/DSTL006/DSTL006_List';
import { DSTL007_Send } from '../DST/DSTL007';
import { DSTS004_Answer } from '../DST/DSTS004/DSTS004_Answer';
import { DSTS004_Detail } from '../DST/DSTS004/DSTS004_Detail';
import { DSTS004_List } from '../DST/DSTS004/DSTS004_List';
import { DSTS004_Regist } from '../DST/DSTS004/DSTS004_Regist';
import { DSTS006_List } from '../DST/DSTS006/DSTS006_List';
import { DSTSL001_List } from '../DST/DSTSL001';
import { DSTSL001_Detail } from '../DST/DSTSL001/DSTSL001_Detail';
import { DSTSL001_Regist } from '../DST/DSTSL001/DSTSL001_Regist';
import { DSTT001_List, DSTT001_Regist } from '../DST/DSTT001';
import { DSTT007_Detail } from '../DST/DSTT007';
import { DSTT007_List } from '../DST/DSTT007/DSTT007_List';
import { changeMobileMenuName } from './action';
import { getMenuData } from './function';
import { SendSMSRegist } from '../DST/DSTL007/DSTL007_Send_Child/SendSMSRegist';
const MenuBody = ({ isTeacher }) => {
    const { depths: name, menu, id, props, } = useSelector((state) => getMenuData(state), shallowEqual);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const historyStore = useHistoryStore();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [name, menu]);
    useEffect(() => {
        if (name === 'home')
            dispatch(changeMobileMenuName(''));
        else {
            let changeMenu = `menu.${menu.menu}`;
            if (menu.menu === 'reservation' && !isTeacher) {
                dispatch(changeMobileMenuName(t('menu.liveRoom')));
                return;
            }
            switch (menu.detail) {
                case 'answer':
                case 'answer_modify':
                    return;
                case 'send':
                    changeMenu = 'menu.sendToSMS';
                    break;
                case 're_regist':
                    changeMenu = `menu.qnaRequestion`;
                    break;
                case 'modify':
                    changeMenu = `menu.${menu.menu}Modify`;
                    break;
                case 'regist':
                    if (menu.menu === 'qna')
                        return;
                    changeMenu = `menu.${menu.menu}Regist`;
                    break;
                case 'question_list':
                    changeMenu = 'menu.qnaRequestionList';
                    break;
                case 'detail':
                    switch (menu.menu) {
                        case 'reservation':
                            changeMenu = 'menu.reservationDetail';
                            break;
                        case 'previous-conference':
                            changeMenu = 'menu.previousReservationDetail';
                            break;
                        case 'attendanceStatus':
                            changeMenu = isMobile ? props?.title : 'menu.attendanceStatus';
                            break;
                        default:
                            return;
                    }
            }
            dispatch(changeMobileMenuName(t(changeMenu)));
        }
    }, [name, menu, props, isMobile, isTablet, isDesktop]);
    useEffect(() => {
        return () => {
            if (menu.menu === 'assignment' || menu.menu === 'test') {
                const isTest = menu.menu === 'test';
                historyStore.clearHistoryForPage(isTest ? 'testListPage' : 'assignmentListPage');
            }
            if (menu.menu === 'qna') {
                historyStore.clearHistoryForPage('qnaListPage');
            }
            if (menu.menu === 'notice' || menu.menu === 'resource') {
                const isResource = menu.menu === 'resource';
                historyStore.clearHistoryForPage(isResource ? 'resourcePage' : 'noticePage');
            }
        };
    }, [name, menu?.menu]);
    if (!menu?.menu)
        return null;
    if (name === 'home')
        return null;
    if (name === 'myRoom' || name === 'learningManagement') {
        if (menu.menu === 'assignment' || menu.menu === 'test') {
            const isTest = menu.menu === 'test';
            switch (menu.detail) {
                case 'list':
                    return React.createElement(DSTL001_List, { isTeacher: isTeacher, isTest: isTest });
                case 'regist':
                case 'modify':
                    return (React.createElement(DSTP001_Regist, { ...props, id: id, isTest: isTest, isTeacher: isTeacher, isModify: menu.detail === 'regist' ? false : true }));
                case 'detail':
                    if (isTeacher) {
                        return React.createElement(DSTL001_Detail, { isTest: isTest, test_id: id, props: props });
                    }
                    else {
                        return React.createElement(DSSL001_Detail, { isTest: isTest, test_id: id, props: props });
                    }
                case 'submit_detail_view':
                    return (React.createElement(MobileTabLayout, { tabList: [
                            {
                                id: 1,
                                component: [React.createElement(DSTL001_Submit_View, { ...props, key: id })],
                            },
                        ], showPageButton: false, isSubmit: true, test_id: id }));
                case 'submit_view':
                    return (React.createElement(MobileTabLayout, { tabList: [
                            {
                                id: 1,
                                component: [
                                    React.createElement(DSTL001_Submit_View, { key: id, test_id: id, member: props }),
                                ],
                            },
                        ], showPageButton: false, isSubmit: true, 
                        // previousPageList={{
                        // 	title: t('assignment.goBack'),
                        // 	menuDetail: 'detail',
                        // 	props: { mobilePage: 1 },
                        // }}
                        test_id: id }));
                case 'submit_detail':
                    return React.createElement(DSTL001_Submit_Detail, { test_id: id, isTest: isTest });
            }
        }
        else if (menu.menu === 'attendanceStatus') {
            switch (isMobile) {
                case true:
                    switch (menu.detail) {
                        case 'detail':
                            return React.createElement(DSTL006_Detail, { test_id: id, props: props });
                        case 'list':
                            return React.createElement(DSTL006_List, { isTeacher: isTeacher });
                    }
                case false:
                    return React.createElement(DSTL006_List, { isTeacher: isTeacher });
            }
        }
        else if (menu.menu === 'gradeCard') {
            return React.createElement(DSSL007_List, null);
        }
        else if (menu.menu === 'sendSMS') {
            switch (menu.detail) {
                case 'regist':
                    return React.createElement(SendSMSRegist, { selectedId: id, ...props });
                case 'list':
                    return React.createElement(DSTL007_Send, { ...props, id: isMobile ? id : undefined });
            }
        }
    }
    else if (name === 'liveRoom') {
        if (menu.menu === 'reservation' || menu.menu === 'previous-conference') {
            const isClosed = menu.menu === 'reservation' ? false : true;
            switch (menu.detail) {
                case 'list':
                    return (React.createElement(DSTT001_List, { isTeacher: isTeacher, isPrevious: menu.menu === 'previous-conference', isClosed: isClosed }));
                case 'detail':
                    return (React.createElement(DSTT001_Regist, { id: id, isPrevious: menu.menu === 'previous-conference', isModify: false, isDetail: true, isTeacher: isTeacher }));
                case 'regist':
                    return React.createElement(DSTT001_Regist, { isModify: false });
                case 'modify':
                    return React.createElement(DSTT001_Regist, { isModify: true, id: id });
            }
        }
        else if (menu.menu === 'reply') {
            switch (menu.detail) {
                case 'list':
                    return React.createElement(DSTT007_List, { isTeacher: isTeacher });
                case 'modify':
                    return React.createElement(DSTT007_Detail, { id: id, isRegist: true, isModify: true });
                case 'regist':
                    return React.createElement(DSTT007_Detail, { id: id, isRegist: true });
                case 'detail':
                    return React.createElement(DSTT007_Detail, { id: id, isRegist: false });
            }
        }
        else if (menu.menu === 'content') {
            switch (menu.detail) {
                case 'list':
                    return React.createElement(DSTS006_List, null);
            }
        }
    }
    else if (name === 'learningSupportRoom') {
        if (menu.menu === 'notice') {
            switch (menu.detail) {
                case 'modify':
                    return React.createElement(DSTSL001_Regist, { isResource: false, ...props, isTeacher: isTeacher });
                case 'regist':
                    return React.createElement(DSTSL001_Regist, { isResource: false, ...props, isTeacher: isTeacher });
                case 'list':
                    return React.createElement(DSTSL001_List, { isResource: false, ...props, isTeacher: isTeacher });
                case 'detail':
                    return React.createElement(DSTSL001_Detail, { ...props, isTeacher: isTeacher });
            }
        }
        else if (menu.menu === 'resource') {
            switch (menu.detail) {
                case 'modify':
                    return React.createElement(DSTSL001_Regist, { isResource: false, ...props, isTeacher: isTeacher });
                case 'regist':
                    return React.createElement(DSTSL001_Regist, { isResource: true, ...props, isTeacher: isTeacher });
                case 'list':
                    return React.createElement(DSTSL001_List, { isResource: true, ...props, isTeacher: isTeacher });
                case 'detail':
                    return React.createElement(DSTSL001_Detail, { ...props, isTeacher: isTeacher });
            }
        }
        else if (menu.menu === 'qna') {
            switch (menu.detail) {
                case 'list':
                    return (React.createElement(DSTS004_List, { ...props, isTeacher: isTeacher, id: id, previousData: props }));
                case 'detail':
                    return React.createElement(DSTS004_Detail, { ...props, root_id: id });
                case 'answer':
                    return React.createElement(DSTS004_Answer, { id: id, isTeacher: isTeacher, ...props });
                case 'answer_modify':
                    return React.createElement(DSTS004_Answer, { id: id, isTeacher: isTeacher, ...props });
                case 'regist':
                    return React.createElement(DSTS004_Regist, { id: id, isTeacher: isTeacher, ...props });
                case 're_regist':
                    return isTeacher ? (React.createElement(DSTS004_Answer, { id: id, isTeacher: isTeacher, ...props })) : (React.createElement(DSTS004_Regist, { id: id, isRetry: true, isTeacher: isTeacher, ...props }));
                case 'question_list':
                    return isTeacher ? (React.createElement(DSTS004_Answer, { id: id, isTeacher: isTeacher, isPopUp: true, ...props })) : (React.createElement(DSTS004_Regist, { id: id, isTeacher: isTeacher, isPopUp: true, ...props }));
            }
        }
    }
    return React.createElement("div", null, menu?.menu);
};
export default MenuBody;
