import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Button, SearchBar } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
const MemberHeaderStyled = styled.div `
	display: flex;
	justify-content: space-between;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	flex-wrap: wrap;
	gap: 8px;

	.active-bar {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-end;
		flex-shrink: 0;
		position: relative;
	}

	.member {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h3-font-family, 'Noto Sans KR', sans-serif);

		font-weight: var(--h5-font-weight, 700);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		display: flex;
		align-items: center;
		gap: 20px;
	}

	${props => (props.$isMobile ? mobileStyles : props.$isDesktop ? desktopStyles : tabletStyles)}
`;
const mobileStyles = css `
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	.search-bar {
		input {
			width: unset;
			font-size: var(--b6-font-size, 10px);
			line-height: var(--b6-line-height, 20px);
			letter-spacing: var(--b6-letter-spacing, -0.02em);
			font-weight: var(--b6-font-weight, 400);
		}
		svg {
			width: 12px;
			height: 12px;
		}
	}

	.member {
		font-size: var(--h5-font-size, 14px);
		line-height: var(--h5-line-height, 24px);
		min-width: 95px;
		flex-direction: row-reverse;
		justify-content: space-between;
		width: 100%;

		p {
			text-align: center !important;
		}
	}
`;
const tabletStyles = css `
	align-items: center;
	gap: 6px;

	.active-bar {
		flex: 1;
		.search-bar {
			width: 100%;
			&.sm {
				padding: 6px 10px;
			}
			input {
				width: unset;
				font-size: 10px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
			}
		}
	}

	.member {
		font-size: var(--h5-font-size, 14px);
		line-height: 150%;
		min-width: 95px;
		p {
			text-align: center !important;
		}
	}
`;
const desktopStyles = css `
	flex-direction: row;
	align-items: center;

	.search-bar {
		input {
			width: unset;
			font-size: var(--b6-font-size, 12px);
			line-height: var(--b6-line-height, 20px);
			letter-spacing: var(--b6-letter-spacing, -0.02em);
			font-weight: var(--b6-font-weight, 400);
		}
		svg {
			width: 12px;
			height: 12px;
		}
	}

	.member {
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		min-width: 95px;
	}
`;
export const SendMemberHeader = React.memo(({ searchOption, handlerFilterList, handlerDelete, isDetail = false, totalTableList = 0, isPopup = false, isRegist = false, }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const { t } = useTranslation();
    const [searchContent, setSearchContent] = React.useState('');
    React.useEffect(() => {
        setSearchContent(searchOption);
    }, [searchOption]);
    return (React.createElement(MemberHeaderStyled, { className: "send-member-header", "$isMobile": isMobile, "$isDesktop": isDesktop },
        React.createElement("div", { className: "member", style: isMobile ?
                { flexDirection: isRegist ? 'row' : 'row-reverse' } : {} },
            !isPopup && isRegist && (React.createElement(Button, { text: "common.selectiveDelete", className: `btn_default ${isMobile ? 'mobile-xs' : isTablet ? 'sm' : 'xmd'}`, onClick: handlerDelete, styles: { buttonWrap: { width: isDesktop ? '90px' : '86px', height: isDesktop ? '48px' : isTablet ? '38px' : '32px' } } })),
            (!isMobile || !isPopup) && ((isPopup && isRegist) ? t('sendSMS.everyStudentCount', { count: totalTableList }) : t('conference.receiverCount', { count: totalTableList }))),
        React.createElement("div", { className: "active-bar", style: { gap: 14 } },
            React.createElement(SearchBar, { className: `${!isMobile ? 'search-bar-qna' : 'search-bar-mobile'}`, filterData: "keyword", handlerFilterList: handlerFilterList, isMember: false, defaultSearchContent: searchContent }))));
});
