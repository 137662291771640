import { throttle } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../../../common';
import { EmptyTable } from '../../../../common/option';
import { useGlobalState, useHistoryStore } from '../../../../GlobalStateProvider';
import { useQnaList } from '../../../../server/qna';
import { TOGGLE_POPUP } from '../../../base/popup';
import { updateMenu } from '../../../menu/action';
import { QNA_STATUS, QNA_TYPE } from '../constant';
import { DSTS004_Detail } from '../DSTS004_Detail';
import { getQnAListOption } from './functions';
import { QnAItem } from './QnAItem';
import { QnAListInfo } from './QnAListInfo';
import { QnAStatistic } from './QnAStatistic';
import { QnAListTableStyled } from './QnAStyled';
import { getUserId } from '../../../base/account';
export const QnAListTable = React.memo(({ filterItems, isTeacher, isRetry = false, isHome = false, id = undefined, isPopUp = false, isHistory = false, hideDetail, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const dispatch = useDispatch();
    const historyStore = useHistoryStore();
    const user_id = useSelector((state) => getUserId(state));
    // 로드 여부
    const [initLoad, setInitLoad] = useState(true);
    // 테이블에 사용되는 정보
    const [page, setPage] = useState(0); // 페이지 번호
    const [testData, setTestData] = useState([]); // Q&A 리스트 데이터
    const [questionData, setQuestionData] = useState([]); // 질문 데이터
    const [totalCount, setTotalCount] = useState(0); // 전체 데이터 개수
    const [count, setCount] = useState(isHome ? 5 : 10); // 화면에 보일 데이터 수
    // 검색 조건
    const [category, setCategroy] = React.useState('');
    const [answerState, setAnswerState] = React.useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [waiting, setWaiting] = useState(true);
    const [openCount, setOpenCount] = useState(0); // 답변 대기 수
    const [closedCount, setClosedCount] = useState(0); // 답변 완료 수
    // 필독 리스트
    const [searchOption, setSearchOption] = useState({});
    // detail ID
    const [selectedId, setSelectedId] = useState();
    // (교사만 해당) 검색 조건에 답변자로 포함 되어있는 경우에만 isAnswer = true
    const { isAnswer, isContent } = useMemo(() => {
        return {
            isAnswer: isTeacher && searchOption?.hasOwnProperty('answerer_author'),
            isContent: searchOption?.hasOwnProperty('answecontentrer_author'),
        };
    }, [isTeacher, searchOption]);
    const { data, refetch } = useQnaList({
        ...(isHistory ? { root_id: id } : {}),
        offset: page === 0 ? 0 : page * count,
        limit: count,
        created_before: endDate && moment(endDate).isValid()
            ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
            : undefined,
        created_after: startDate && moment(startDate).isValid()
            ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
            : undefined,
        orderby: isHistory
            ? ['creation_time DESC']
            : ['importance DESC', 'creation_time DESC'],
        status: isHistory
            ? hideDetail
                ? [QNA_STATUS.CLOSED]
                : [QNA_STATUS.CLOSED, QNA_STATUS.OPEN]
            : isTeacher
                ? isHome || waiting
                    ? [QNA_STATUS.OPEN]
                    : undefined
                : [QNA_STATUS.CLOSED, QNA_STATUS.OPEN],
        ...(!isTeacher && (isHome || waiting ? { author_id: user_id } : {})),
        ...(isContent ? { group_by_round: true } : { with_respondent: true }),
        keyword: searchOption['keyword'],
        ...(category === '' || category === 'select' ? {} : { category }),
        ...(answerState === '' || answerState === 'select' ? {} : { status: answerState }),
        type: isAnswer ? QNA_TYPE.ANSWER : QNA_TYPE.QUESTION,
    }, 'qnaList');
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    // 테이블에 사용될 데이터 - headTitleList : head key, tableReadDataKeys : 아이템 key
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => getQnAListOption(isHome, isRetry, isTeacher, isMobile), [isHome, isRetry, isTeacher, isMobile]);
    useEffect(() => {
        const initializeState = async () => {
            const previousPage = historyStore.getRecentPageHistory('qnaListPage');
            const searchOptions = previousPage?.searchOption;
            if (searchOptions) {
                for (const key in searchOptions) {
                    switch (key) {
                        case 'searchOption':
                            setSearchOption(searchOptions[key]);
                            break;
                        case 'endDate':
                            setEndDate(searchOptions[key]);
                            break;
                        case 'startDate':
                            setStartDate(searchOptions[key]);
                            break;
                        case 'answerState':
                            setAnswerState(searchOptions[key]);
                            break;
                        case 'category':
                            setCategroy(searchOptions[key]);
                            break;
                        case 'waiting':
                            setWaiting(searchOptions[key]);
                            break;
                    }
                }
            }
            setPage(previousPage?.prePage || 0);
            setInitLoad(true);
        };
        initializeState();
        return () => {
            setInitLoad(false);
        };
    }, []);
    useEffect(() => {
        APP.eventManager.subscribe('REFETCH_QNA_LIST', refetchList);
        return () => {
            APP.eventManager.unsubscribe('REFETCH_QNA_LIST', refetchList);
        };
    }, []);
    useEffect(() => {
        setCount(isHome ? 5 : 10);
    }, [isHome]);
    useEffect(() => {
        if (!initLoad)
            return;
        if (isHistory && !id)
            return;
        refetchList();
    }, [
        initLoad,
        searchOption,
        isRetry,
        waiting,
        page,
        category,
        answerState,
        startDate,
        endDate,
        id,
        isHistory,
    ]);
    useEffect(() => {
        if (data) {
            const { total_count, items, found_count, open_count, closed_count } = data;
            if (total_count > 0 && found_count === 0) {
                const maxPage = Math.max(0, Math.ceil(total_count / count) - 1);
                setPage(maxPage);
                return;
            }
            if (found_count > 0) {
                const testItem = filterItems
                    ? items.filter(item => !filterItems.includes(item.id))
                    : items;
                setTestData(id ? testItem.filter(item => item.root_id === id) : testItem);
                setTotalCount(isHome ? Math.min(total_count, 5) : total_count);
                setOpenCount(open_count);
                setClosedCount(closed_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
        setOpenCount(0);
        setClosedCount(0);
    }, [data, filterItems]);
    const handlerMoreData = (page) => setPage(page);
    const downloadFilter = useMemo(() => {
        return {
            ...(isHistory ? { root_id: id } : {}),
            created_before: endDate && moment(endDate).isValid()
                ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
                : undefined,
            created_after: startDate && moment(startDate).isValid()
                ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
                : undefined,
            orderby: isHistory
                ? ['creation_time DESC']
                : ['importance DESC', 'creation_time DESC'],
            status: isTeacher
                ? isHome || waiting
                    ? [QNA_STATUS.OPEN]
                    : undefined
                : [QNA_STATUS.CLOSED, QNA_STATUS.OPEN],
            ...(!isTeacher && (isHome || waiting ? { author_id: user_id } : {})),
            ...(isContent ? { group_by_round: true } : { with_respondent: true }),
            keyword: searchOption['keyword'],
            ...(category === '' || category === 'select' ? {} : { category }),
            ...(answerState === '' || answerState === 'select' ? {} : { status: answerState }),
            type: isAnswer ? QNA_TYPE.ANSWER : QNA_TYPE.QUESTION,
        };
    }, [
        isHistory,
        id,
        page,
        count,
        endDate,
        startDate,
        waiting,
        searchOption,
        isAnswer,
        answerState,
        category,
        isContent,
        isTeacher,
        isHome,
        user_id,
    ]);
    /**
     *
     */
    const renderQnAStatistics = useMemo(() => (React.createElement(QnAStatistic, { totalCount: totalCount, openCount: openCount, closedCount: closedCount })), [totalCount, openCount, closedCount]);
    /**
     *
     */
    const renderQnAListInfo = useMemo(() => (React.createElement(QnAListInfo, { answerState: answerState, category: category, startDate: startDate, endDate: endDate, searchOption: searchOption, waiting: waiting, setStartDate: setStartDate, setEndDate: setEndDate, setAnswerState: setAnswerState, setCategroy: setCategroy, setSearchOption: setSearchOption, setWaiting: setWaiting, downloadFilter: downloadFilter })), [downloadFilter, waiting, answerState, category, startDate, endDate, searchOption]);
    /**
     * (onClick) 재질문 -> 자세히 보기 상세 페이지로 이동
     */
    const goToDetailPage = (root_id, index) => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DSTS004_Detail,
            componentProps: {
                root_id,
                depths: index,
                isPopup: true,
                isHistory: true,
            },
            width: 1040,
            height: 'unset',
            title: t('supportRoom.showQuestion'),
        });
    };
    /**
     * (onClick) 상세 페이지로 이동
     *
     * Q&A 가 열려있는 경우 + 강사, 조교인 경우
     * 	-> 답변하기 페이지로 이동
     * 그 외
     *  -> 상세 페이지로 이동
     */
    const handlerSelectItem = (item) => {
        if (isHistory) {
            setSelectedId(selectedId === item.id ? undefined : item.id);
            return;
        }
        // 새로운 페이지로 이동할 때 현재 상태를 기록
        const historyItem = {
            preItems: {},
            props: {
                isTeacher,
                isRetry,
                isHome,
                id,
                isPopUp,
                isHistory,
                hideDetail,
            },
            prePage: page,
            searchOption: {
                searchOption,
                endDate,
                startDate,
                answerState,
                category,
                waiting,
            },
            pageName: 'qnaListPage',
        };
        historyStore.pushHistory(historyItem);
        if (QNA_STATUS.OPEN === item.status && isTeacher) {
            dispatch(updateMenu('learningSupportRoom', { menu: 'qna', detail: 'answer' }, item.root_id, {
                depths: Math.max(item.number - 1, 0),
                answer_number: item.number,
                isModify: false,
                isTeacher,
                isRequestion: isRetry,
            }));
        }
        else {
            dispatch(updateMenu('learningSupportRoom', { menu: 'qna', detail: 'detail' }, item.root_id, {
                title: item.title,
                isHistory,
                depths: Math.max(item.number - 1, 0),
                isHidePaging: true,
                isPopup: isPopUp,
                isContent: isContent || isAnswer,
            }));
        }
    };
    const renderMoreItem = (item, styles) => {
        return (React.createElement("div", { className: "more_item" },
            React.createElement(DSTS004_Detail, { root_id: item.root_id, depths: item.number - 1, isHidePaging: true, isContent: isContent || isAnswer, isHistory: isHistory, isPopup: false })));
    };
    /**
     * 9
     */
    const renderItem = (item, styles, index) => {
        // 필요한 데이터를 계산
        const listData = (isAnswer && searchOption['author']) || (isContent && item.type === 'answer')
            ? questionData.filter(data => item.number - data.number === 1 && item.root_id === data.root_id)
            : null;
        const dataToUse = listData && listData.length > 0 ? listData[0] : item;
        const isSelected = item.id === selectedId;
        if (!dataToUse)
            return null;
        return (React.createElement(React.Fragment, null, tableReadDataKeys.map(headItem => {
            const number = index + page * count + 1;
            return (React.createElement("div", { key: `${headItem.title}-${item.id}`, className: `body-item ${isSelected && (isMobile ? headItem.title === 'content' : true)
                    ? 'bold-pur'
                    : ''} ${headItem.title}`, style: {
                    flex: headItem.space,
                    maxWidth: headItem?.maxWidth ? headItem.maxWidth : 'auto',
                    ...styles?.bodyItem,
                } },
                React.createElement(QnAItem, { isHome: isHome, isAnswer: isAnswer, isContent: isContent, isTeacher: isTeacher, isTablet: isTablet, isMobile: isMobile, isSelected: isSelected, isHistory: isHistory, headItem: headItem, item: dataToUse, number: isHistory
                        ? Math.min(count, totalCount) - number + 1
                        : number, handlerShowDetail: goToDetailPage })));
        })));
    };
    /**
     * (Empty) 데이터가 없을 경우
     */
    const renderEmpty = useMemo(() => React.createElement(EmptyTable, { title: "supportRoom.questionEmpty" }), []);
    return (React.createElement(QnAListTableStyled, { className: "qna-list-table", "$isHistory": isHistory, "$isHome": isHome, "$isRetry": isRetry, "$isTablet": isTablet, "$isPopUp": isPopUp, "$isMobile": isMobile, "$isTeacher": isTeacher },
        !isHistory && !isRetry && !isHome && !id && renderQnAListInfo,
        React.createElement("div", { className: `qna-section ${isHistory && 'history'}` },
            isTeacher && !isRetry && !isHome && !id && renderQnAStatistics,
            React.createElement(Table, { className: isRetry ? 'qna-list-002' : 'qna-list-001', showCheckBox: false, isInifinite: isRetry || isHome ? true : false, selectedId: selectedId, page: page, showCount: count, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderItem: renderItem, renderMoreItem: renderMoreItem, renderEmpty: totalCount <= 0 && renderEmpty, handlerSelectItem: hideDetail ? isMobile ? handlerSelectItem : undefined : handlerSelectItem, handlerMoreData: hideDetail ? undefined : handlerMoreData, forceShowheader: !isRetry && (isHistory || isMobile || headTitleList.length <= 0)
                    ? false
                    : true }))));
});
