import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { MobileTabLayout } from '../../base/layout';
import { RegistButton } from '../RegistButton';
import { SendSMSListTable } from './DSTL007_Send_Child';
import { SendSMSContent } from './DSTL007_Send_Child/SendSMSContent';
const DSTL007SendStyled = styled.div `
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: ${props => (props.$isMobile ? '0px' : props.$isTablet ? '20px' : '20px')};
	position: relative;
	width: 100%;
	height: 100%;
	${props => props.$openMemberList && ` overflow-x: hidden;`}

	.ql-editor {
		word-break: break-all;
	}

	${props => !props.$isMobile &&
    `
		.open_member_list {
			display: flex;
			position: absolute;
			width: 100%;
			background: rgba(16, 16, 16, 0.3);
			z-index: 2;
			border-radius: 10px;
			justify-content: center;
			align-items: center;
			min-height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				border: none;
			

			
		}
		.right_box {
			position: relative;
			flex: 2;
			display: flex;
			flex-direction: row;
			position: relative;
			
			padding: 20px;

			width: 100%;
			max-width: 70%;
			${props.$isTablet && `padding: 0;`}
		}
	`}
	${props => props.$isMobile &&
    `	
			${props.$mobilePage === 'send' && `padding: 20px`};
	
			.tab-item {
				display:flex;
				height: 100%;
			}

			.open_member_list {
				display: flex;
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: rgba(16, 16, 16, 0.3);
				z-index: 2;
				justify-content: center;
    			align-items: center;
			}

			.open-member-list {
				background: #fff;
				width: 90%;
				height: 90%;
				border-radius: 10px;
				padding: 16px 20px;
				
			}
	`}
		${props => props.$isTablet &&
    `
		padding: 20px ;
		.mobile-tab-layout {
			width: unset;
			flex: 1;
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px;
			border-radius: 10px;
			padding:20px
		}

		.open_member_list {
			border-radius: 0px;
		}
	`};
`;
export const DSTL007_Send = React.memo(({ mobilePage, id }) => {
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [openMemberList, setOpenMemberList] = useState(false);
    const [sendMemberSetting, setSendMemberSetting] = useState('detail');
    const [selectedId, setSelectedId] = useState('');
    const updateSelectedId = (id) => {
        setSelectedId(id);
        setOpenMemberList(false);
    };
    const renderMobileView = useMemo(() => {
        let content = null;
        switch (mobilePage) {
            case 'send':
                content = (React.createElement(Fragment, { key: "test" },
                    React.createElement(SendSMSContent, { selectedId: selectedId, setSelectedId: setSelectedId, openMemberList: openMemberList, setOpenMemberList: setOpenMemberList, setSendMemberSetting: setSendMemberSetting })));
                break;
            default:
                content = (React.createElement(SendSMSListTable, { selectedId: selectedId, setSelectedId: setSelectedId }));
                break;
        }
        return React.createElement(Fragment, { key: mobilePage || 'test' },
            content,
            " ");
    }, [mobilePage, selectedId, openMemberList, sendMemberSetting]);
    const renderDesktopView = () => (React.createElement(React.Fragment, null,
        React.createElement(SendSMSContent, { selectedId: selectedId, setSelectedId: setSelectedId, openMemberList: openMemberList, setOpenMemberList: setOpenMemberList, setSendMemberSetting: setSendMemberSetting }),
        React.createElement("div", { className: "right_box" },
            React.createElement(SendSMSListTable, { selectedId: selectedId, setSelectedId: updateSelectedId }))));
    const handlerButtonClick = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'regist',
            id: undefined,
        });
    };
    return (React.createElement(DSTL007SendStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop, "$mobilePage": mobilePage, "$openMemberList": openMemberList, className: "dtsl-007-send" },
        isMobile ? (React.createElement(MobileTabLayout, { tabList: [
                {
                    id: 1,
                    component: [renderMobileView],
                },
            ], showNav: mobilePage !== 'list', hideHr: true })) : (renderDesktopView()),
        React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick })));
});
