import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Colors } from '../../../../Colors';
import { Button, Icon } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { postDeleteSendSMS, postModifySendSMS, postRegistSendSMS, useSendSMSList, useSMSReceiversList, } from '../../../../server/sms';
import { DeletePopUpLayout } from '../../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../../base/popup';
import { ChoiceMemberList } from './ChoiceMemberTable';
import { substitutes } from './SendSMSRegist';
const SendSMSContentStyled = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;

	.divider {
		background: var(--color-grey-c, #e6e8ed);
		align-self: stretch;
		flex-shrink: 0;
		height: 1px;
	}

	.empty-sms-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		gap: 8px;
	}

	.select-sms {
		font-size: 18px;
		font-weight: 400;
		line-height: 36px;
		letter-spacing: -0.02em;
		color: #83868A;
	}

	.sms-info {
		display: flex;
		flex-direction: column;
		gap: 8px;	
		width: 100%;
	}

	.btn-recipient-setting {
		background: var(--color-white, #ffffff);
		border-radius: 100px;
		border-style: solid;
		border-color: var(--tur, #3ab2bf);
		border-width: 1px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;

		p {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'Noto Sans KR', sans-serif;
			${props => !props.$isDesktop
    ? `
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
			`
    : `
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);`}
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		${props => props.$isDesktop &&
    `
			position: absolute;
			right: 24px;
			padding: 4px 18px 4px 18px;
			z-index: 1;	
			gap: 8px;
		`}

		${props => props.$isTablet &&
    `
			position: absolute;
			top: 16px;
			right: 24px;
			padding: 4px 6px 4px 6px;
			z-index: 1;	
		`}


		${props => props.$isMobile &&
    `  
			padding: 6px 10px 6px 10px;
			gap: 6px;
		`}

	}

	${props => props.$isMobile
    ? `
				gap: 12px;
				flex: 1;
			  `
    : `
				background: var(--color-white, #ffffff);
				border-radius: 10px;
				border-style: solid;
				border-color: var(--color-grey-c, #e6e8ed);
				border-width: 1px;

				${props.$isDesktop
        ? `
							min-width: 400px;
							gap: 14px;
							padding: 26px;
						`
        : `
							min-width: 270px;
							gap: 12px;
							padding: 20px;
						`}
			  `}

	span,
	.overText {
		position: relative;
		text-align: left;

		${props => props.$isDesktop
    ? `
					font-size: var(--b3-font-size, 16px);
					line-height: var(--b3-line-height, 32px);
					letter-spacing: var(--b3-letter-spacing, -0.02em);
					font-weight: var(--b3-font-weight, 400);
				  `
    : `
					font-size: 14px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
				  `}
	}

	.text-grey {
		color: var(--color-grey-l, #83868a);
	}

	.sms-wrap {
		flex-direction: column;
		flex: 1;
		gap: ${props => (props.$isDesktop ? '20px' : '12px')};
	}

	.send-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${props => (props.$isDesktop ? '12px' : '10px')};
	}

	textarea {
		&.focus {
			outline: 2px solid transparent;
			outline-offset: 2px;
			box-shadow: 0 0 5px $tur; // 흐림 효과 추가
		}

		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		width: 100%;
		resize: none;
		overflow: hidden;
		padding: 9px;

		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: 150%;
		font-weight: var(--b4-font-weight, 400);
	}

	.sms-title {
		height: 40px !important;
	}

	.sms-content {
		min-height: 100px;
		flex: 1;
	}

	.xmd {
		p {
			font-size: 14px;
			line-height: 20px;
			display: flex;
			justify-content: center;
		}
	}

	.message-text {
		min-height: ${props => (props.$isDesktop ? '35px' : props.$isTablet ? '25px' : '20px')};
	}

	.message-length {
		text-align: right;
		font-size: 14px;
		color: var(--color-grey-b, #7e8289);
	}

	.btn-container {
		margin: 0;
	}

	.send-type {
		span {
			font-size: ${props => (props.$isDesktop ? '14px' : '12px')};
		}
	}

	.input_send {
	}

	${props => props.$isMobile &&
    `
			.mobile-full {
				border-radius: 6px;
				p {
					justify-content: center;
				}
			}

			.btn-container {
				width: 100%;
			}
		`}

	.sms-preview-content {
		word-break: break-word;
		flex: 1;
		width: 100%;	
    	min-height: 100px;
	    border-radius: 4px;
    	border-style: solid;
    	border-color: var(--color-grey-w, #b6bac0);
    	border-width: 1px;
	    white-space: pre-wrap;
		padding: 9px;
    	text-align: left;
    	font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
    	font-size: var(--b4-font-size, 14px);
    	line-height: 150%;
    	font-weight: var(--b4-font-weight, 400);
		span {
			text-align: left;
    		font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
    		font-size: var(--b4-font-size, 14px);
    		line-height: 150%;
    		font-weight: var(--b4-font-weight, 400);
		}
	}
`;
export const SendSMSContent = React.memo(({ selectedId, setSelectedId, openMemberList, setOpenMemberList, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const deletePostMutation = useMutation(postDeleteSendSMS);
    const [isImmediately, setIsImmediately] = React.useState(true); //발송일자 checkBox
    const [isFixNumber, setIsFixNumber] = React.useState(true); //발신번호 checkBox
    const [receiverMemberList, setReceiverMemberList] = useState([]);
    const [startDate, setStartDate] = useState(''); // 발송일
    const [startTime, setStartTime] = useState(''); // 발송시간
    const [directNumber, setDirectNumber] = useState(''); // 발신번호
    const [title, setTitle] = useState(''); // 메시지 제목
    const [message, setMessage] = useState(''); // 발송 메시지
    const [isNormal, setIsNormal] = useState(true); // 발송 타입 (일반, 치환)
    const [status, setStatus] = useState(''); // 발송 상태 (created, sent, failed)
    // 한글 2바이트, 영문 1바이트로 계산
    const getCoolsmsByteLength = (message) => [...message].reduce((bytes, char) => bytes + (char.charCodeAt(0) <= 127 ? 1 : 2), 0);
    const messageBytes = getCoolsmsByteLength(message);
    const isSMSLength = messageBytes <= 90;
    const messageLength = isSMSLength ? `${messageBytes}/90` : "MMS";
    // 현재 시간
    const now = useMemo(() => moment(), []);
    const registSendSMSMutation = useMutation(postRegistSendSMS);
    const modifySendSMSMutation = useMutation(postModifySendSMS);
    const { data, refetch } = useSendSMSList({
        id: selectedId,
    });
    const { data: receiversData, refetch: receiversRefetch } = useSMSReceiversList({
        id: selectedId,
    });
    const [fetchKey, setFetchKey] = useState(0);
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    const refetchReceiversList = throttle(() => receiversRefetch(), 1000, { leading: true, trailing: false });
    const cleanup = () => {
        setIsImmediately(true);
        setStartDate(moment().format('YYYY-MM-DD'));
        setStartTime(moment().format('HH:mm'));
        setIsFixNumber(true);
        setDirectNumber('');
        setTitle('');
        setMessage('');
        setIsNormal(true);
        ;
        setOpenMemberList(false);
    };
    useEffect(() => {
        if (selectedId !== '') {
            setFetchKey((prev) => prev + 1);
            refetchReceiversList();
        }
        else
            cleanup();
    }, [selectedId]);
    useEffect(() => {
        refetchList();
    }, [fetchKey]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            if (items && items.length >= 1) {
                const item = items[0];
                setIsImmediately(item.send_time === '' ? true : false);
                setStartDate(moment(item.send_time).format('YYYY-MM-DD'));
                setStartTime(moment(item.send_time).format('HH:mm'));
                setIsFixNumber(item.phone_number.includes('*') ? true : false);
                setDirectNumber(item.phone_number.includes('*') ? '' : item.phone_number);
                setTitle(item.title);
                setMessage(item.content);
                setStatus(item.status);
                setIsNormal(item.type === 'normal' ? true : false);
                // const newReceiverMemberList =
                // 	!_.isEqual(receiverMemberList, item.receivers) &&
                // 		receiverMemberList.length !== 0
                // 		? receiverMemberList
                // 		: item.receivers;
                // // @ts-ignore
                // setReceiverMemberList(isMobile ? newReceiverMemberList : item.receivers);
                return;
            }
        }
        !isMobile && cleanup();
    }, [data]);
    useEffect(() => {
        if (receiversData) {
            const { receivers } = receiversData;
            if (receivers && receivers.length >= 1) {
                const simpleItems = receivers.map(receiver => ({
                    id: receiver[1],
                    data: receiver,
                }));
                setReceiverMemberList(simpleItems);
                return;
            }
        }
    }, [receiversData]);
    const previewRef = React.useRef(null);
    const [preview, setPreview] = useState(''); // 미리보기 메시지
    useEffect(() => {
        const previewTitle = title.length > 0 ? `<span style="font-weight: bold;">${title}</span>\n` : '';
        setPreview(previewTitle + message);
    }, [title, message]);
    useEffect(() => {
        if (!previewRef.current)
            return;
        if (!preview) {
            previewRef.current.innerHTML = "<span style='color: gray;'>내용 없음</span>";
            return;
        }
        const highlightedText = preview.replace(/#\{(.*?)\}/g, (match, word) => {
            return substitutes.includes(word)
                ? `<span style="color: #8268d9;">${match}</span>`
                : match;
        });
        previewRef.current.innerHTML = highlightedText;
    }, [preview, substitutes]);
    // (onClick) 보내기
    const handlerEdit = async () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'regist',
            id: selectedId,
        });
    };
    const handlerDelete = () => {
        const onDelete = async () => {
            const id = selectedId;
            try {
                APP.eventManager.publish('SHOW_LOADING', true);
                await deletePostMutation.mutateAsync(id);
                APP.eventManager.publish('UPDATE_SEND_SMS');
                if (selectedId === id)
                    setSelectedId(undefined);
            }
            catch (error) {
                console.error('Failed to post deletePost:', error);
                alert(error);
            }
            finally {
                APP.eventManager.publish('SHOW_LOADING', false);
                setSelectedId('');
            }
        };
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: t('assignment.delteInto', { count: 1 }),
                onDelete,
            },
            width: 639,
            title: t(`supportRoom.noticeDelete`),
            isFit: true,
        });
    };
    const handlerOpenMemberList = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            className: 'send-popup',
            component: ChoiceMemberList,
            componentProps: {
                memberList: receiverMemberList,
                isNotChecked: true,
                isPopup: true,
                isNormal: isNormal,
                isRegist: false,
                isDetail: true,
            },
            width: isNormal ? 580 : 730,
            title: '수신자 목록',
            isFit: true,
        });
    };
    if (selectedId === '' && !isMobile) {
        return (React.createElement(SendSMSContentStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement("div", { className: "empty-sms-content" },
                React.createElement(Icon, { icon: "arrow_in_circle_right", size: 40 }),
                React.createElement("div", { className: "select-sms" }, t('sendSMS.selectSMS')))));
    }
    ;
    return (React.createElement(SendSMSContentStyled, { className: "send-sms-content", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement(Button, { className: "btn-recipient-setting", text: `수신자 (${receiverMemberList?.length > 0
                ? receiverMemberList.length.toString().padStart(2, '0')
                : '00'})명`, option: {
                buttonAfterIcon: {
                    show: true,
                    className: isMobile
                        ? ''
                        : openMemberList
                            ? 'arrow-left'
                            : 'arrow-right',
                    name: isMobile ? 'plus' : 'twoarrow',
                    size: isDesktop ? 22 : 12,
                    color: Colors.tur,
                },
            }, onClick: handlerOpenMemberList }),
        React.createElement("div", { className: "field-global-001 sms-wrap" },
            React.createElement("div", { className: "sms-info" },
                React.createElement("div", { className: "field-short" },
                    React.createElement("p", { className: "overText text-grey" }, t('sendSMS.sendType')),
                    React.createElement("p", { className: "overText" }, t(`sendSMS.${isNormal ? 'normal' : 'substitute'}`))),
                React.createElement("div", { className: "send-wrap" },
                    React.createElement("div", { className: "field-short" },
                        React.createElement("p", { className: "overText text-grey" }, t('sendSMS.sendDate')),
                        React.createElement("p", { className: "overText" }, `${startDate}, ${startTime}`)))),
            React.createElement("div", { className: "divider" }),
            React.createElement("div", { className: "send-wrap", style: { flex: '1' } },
                React.createElement("p", { className: "overText message-text text-grey", style: { width: '100%' } }, t('sendSMS.messageContent')),
                React.createElement("div", { className: "sms-preview-content", ref: previewRef }),
                React.createElement("p", { className: "message-length" }, messageLength))),
        React.createElement("div", { className: "btn-container", style: { width: '100%' } },
            status === 'created' && (React.createElement(Button, { className: `btn_white ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('common.modify'), onClick: handlerEdit })),
            React.createElement(Button, { className: `btn_default ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('common.delete'), onClick: handlerDelete }))));
});
