import styled, { css } from 'styled-components';
export const MembersTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;

	.name {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.dropdown {
		width: 100%;
	}

	.body-item {
		position: relative;
	}

	.dropdown-list {
		top: 48px;
	}

	.sms-member-list {
		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			position: relative;
			overflow: auto;
		}
		.table-header {
			border: none !important;
			border-radius: 40px;
		}
	}

	.paging {
		margin-top: 0px;
		gap: 10px;

		button {
			width: 18px;
			height: 18px;

			p {
				min-width: unset;
			}
		}
	}


	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	.table-item-wrap {
		.body-item {
			width: unset;
		}
	}

	.check-btn {
		padding: 8px 0;
	}

	.sms-member-list {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		flex: 1;

		.item {
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px 0px 0px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
			}

			.grey {
				color: var(--color-grey-l, #83868a);
				font-size: var(--b4-font-size, 12px);
			}
		}
	}

	.table-header-item {
		padding-bottom: 10px;
	}
	.all-checked {
		font-size: 12px;
	}
`;
const tabletStyles = css `
	background: var(--color-white, #ffffff);
	border-radius: 10px;
	border-style: solid;
	border-color: var(--color-grey-c, #e6e8ed);
	border-width: 1px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	min-width: 150px;
	flex: 1;

	.sms-member-list {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		align-self: stretch;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			position: relative;
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;

			.table-header-item {
				min-width: unset !important;
				svg {
					width: 18px;
					min-width: unset;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 16px 12px 16px;
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;

			.body-item {
				min-width: unset !important;
				.checkbox-item {
					svg {
						width: 18px;
						min-width: unset;
					}
				}
			}
		}
	}
`;
const desktopStyles = css `
	background: var(--color-white, #ffffff);
	border-radius: 10px;
	border-style: solid;
	border-color: var(--color-grey-c, #e6e8ed);
	border-width: 1px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	min-width: 215px;
	flex: 1;

	.sms-member-list {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		align-self: stretch;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			position: relative;
			text-align: left;
			font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 16px 12px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}
		}
	}
`;
