import { t } from 'i18next';
import React, { useMemo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Button, CheckBox, InputElement, Table } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { TOGGLE_POPUP } from '../../../base/popup';
import { ChoiceMemberList } from './ChoiceMemberTable';
import { SMS_MEMBER_CHECK_LIST } from '../constant';
import { MembersTableStyled } from './MemberListTableStyled';
import { SMSUploadExcel } from './SMSUploadExcel';
import { SendMemberHeader } from './SendMemberHeader';
import { useMemberInfo } from '../../../../server';
import { throttle } from 'lodash';
import { getClassesId } from '../../../base/classes';
import { DeletePopUpLayout } from '../../../base/layout/DeletePopUpLayout';
const MemberListStyled = styled.div `
	gap: 12px;

	.btn-container {
		flex-direction: column;
		justify-content: center;

		.btn_line_light_tur {
			color: var(--color-tur, #3ab2bf);
		}
	}

	.disable {
		background-color: var(--color-grey-c, #e6e8ed);
	}

	.excel-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.member-setting {
		display: flex;
		flex-direction: row;
		gap: 12px;
		flex-shrink: 0;
		align-items: center;
		height: 40px;
		width: 100%;
	}

	.file-item-wrap {
		padding: 8px !important;

		button {
			padding: 1px 8px !important;
		}
		p {
			line-height: 22px !important;
		}
	}

	

	${props => (!props.$isMobile ? desktopStyles : mobileStyles)}
`;
const mobileStyles = css `
	display: flex;
	flex-direction: column;
	gap: 12px;

	.member-list {
		width: 100%;
		gap: 16px;
		display: flex;
		flex-direction: column;
	}

	.member-setting {
		display: flex;
		flex-direction: column;
		gap: 10px;
		flex-shrink: 0;
		align-items: flex-start;
		width: 100%;
		height: fit-content;

		.checkboxk {
			gap: 8px;
		}
	}
		
	.frame-987338 {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.open-header {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: 0px 0px 12px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: space-between;
		flex-shrink: 0;
		position: relative;
	}
	.title-name {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h5-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		letter-spacing: var(--h5-letter-spacing, -0.02em);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		width: 248px;
	}

	.member-table-wrap {
		display: flex;
		flex-direction: column;
		gap: 12px;
		flex: 1;
		overflow: hidden;

		.paging {
			padding-top: 0 !important;
		}
	}
	.header {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.user-list {
		width: 100%;
		flex: 1;
		overflow: hidden;
	}

	.select-members-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		row-gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}
	.select-members {
		background: #ecf7f8;
		border-radius: 12px;
		padding: 20px 0px 20px 0px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		row-gap: 0px;
		align-items: center;
		justify-content: center;
		flex: 1;
		position: relative;
	}

	.button {
		p {
			font-size: 12px;
		}
	}

	.btn_line_light_tur {
		p {
			color: var(--color-black, #101010);
		}
	}

	.mobile-full {
		border-radius: 6px;
		justify-content: center;

		p {
			justify-content: center;
			width: unset;
		}

		&.text-tur {
			p {
				color: var(--tur, #3ab2bf);
			}
		}

		&.text-pink {
			p {
				color: var(--color-pink, #f46790);
			}
		}
	}

	.setting-checkbox {
		display: flex;
		flex-direction: column;
		gap: 4px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
	}

	.substitute-input {
		text-align: center !important;
	}
`;
const desktopStyles = css `

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		gap: 40px;
		padding-left: 70px;

		${props => props.$isTablet && `
			button {
				padding: 8px;
			}	
		`}
	}

	.member-list {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: ${props => (props.$isTablet ? '16px' : '24px')};
		overflow: hidden;
	}

	.user-list {
		width: 100%;
		height: 100%;
		min-height: ${props => (props.$isTablet ? '250px' : '300px')};
	}

	.btn-container {
		margin: auto 0;
		.md_round {
			padding: 4px 18px;
			p {
				min-width: unset;
				line-height: var(--b4-line-height, 28px);
			}
		}
	}

	.member-wrap {
		display: flex;
		height: 100%;
		gap: 20px;
	}

	.setting-checkbox {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		flex-shrink: 0;
	}

	.add-input {
		min-width: 56px;
	}

	.substitute-input {
		min-width: 56px;
	}
`;
export const MemberList = React.memo(({ openMemberList, isNormal, substitutes, receiverMemberList, setReceiverMemberList, memberSetting, originalStudentData, everyStudentData, oneByOneData, excelUploadData, setMemberSetting, setOriginalStudentData, setEveryStudentData, setOneByOneData, setExcelUploadData, }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const classesId = getClassesId();
    const [page, setPage] = useState(0);
    const count = 10;
    // const [currentMemberList, setCurrentMemberList] = useState<SendMemberTableProps[]>([]);
    const [testData, setTestData] = useState([]);
    const [noNumberData, setNoNumberData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [checkList, setCheckList] = useState([]);
    const [searchOption, setSearchOption] = useState({ keyword: undefined });
    // const [memberSetting, setMemberSetting] = React.useState<string>('everyStudent'); //수신자 설정(수강생전체/한 건씩 추가/엑셀 업로드) checkBox
    // const [originalStudentData, setOriginalStudentData] = useState<SendMemberTableProps[]>([]); // 수강생(전체) 원본 데이터터
    // const [everyStudentData, setEveryStudentData] = useState<SendMemberTableProps[]>([]); // 수강생(전체) 데이터
    // const [oneByOneData, setOneByOneData] = useState<SendMemberTableProps[]>([]); // 한 건씩 추가 데이터
    // const [excelUploadData, setExcelUploadData] = useState<SendMemberTableProps[]>([]); // 엑셀 업로드 데이터
    // const [sendNumberValue, setSendNumberValue] = React.useState<string>(''); //발신번호 checkBox
    const [inputValues, setInputValues] = useState(substitutes.map(type => ({ type, value: '' })));
    // 참가자 목록 가져오기
    const { data, refetch } = useMemberInfo({
        class_id: classesId,
        // status: 'created',
        level: ['03'],
        // offset: page === 0 ? 0 : page * count,
    });
    // (리스트 갱신)
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    useEffect(() => {
        refetchList();
    }, []);
    useEffect(() => {
        if (openMemberList)
            return;
        if (isNormal) {
            setMemberSetting('everyStudent');
        }
        else {
            setMemberSetting('oneByOne');
        }
    }, [isNormal, openMemberList]);
    useEffect(() => {
        setTotalCount(0);
        setTestData([]);
        setReceiverMemberList([]);
        setCheckList([]);
        setSearchOption(prev => ({ ...prev, keyword: undefined }));
        if (memberSetting === 'everyStudent') {
            setReceiverMemberList(everyStudentData);
        }
        else if (memberSetting === 'oneByOne') {
            setReceiverMemberList(oneByOneData);
        }
        else if (memberSetting === 'excelUpload') {
            setReceiverMemberList(excelUploadData);
        }
    }, [memberSetting, everyStudentData, oneByOneData, excelUploadData]);
    useEffect(() => {
        setPage(0);
    }, [memberSetting]);
    useEffect(() => {
        if (data) {
            const { total_count, found_count, items } = data;
            if (found_count === 0 && total_count > 0) {
                const maxPage = Math.max(0, Math.ceil(total_count / 10) - 1);
                setPage(maxPage);
            }
            if (total_count > 0) {
                const simpleItems = items.map(item => ({
                    id: item.id,
                    data: [
                        item.nickname + (item.identifier ? `(${item.identifier})` : ""),
                        item.phone_number.replace(/-/g, "")
                    ]
                }));
                setOriginalStudentData(simpleItems);
                // setEveryStudentData(simpleItems);
                setEveryStudentData(simpleItems.filter(item => item.data[1] !== ''));
                // setOriginalStudentData(items);
                // setEveryStudentData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    // useEffect(() => {
    // 	const searchKeyword = searchOption.keyword?.trim().toLowerCase() || '';
    // 	// 검색 필터 적용 (이름|아이디|전화번호)
    // 	const filteredData = searchKeyword !== ''
    // 		? receiverMemberList.filter(item =>
    // 			item.nickname?.toLowerCase().includes(searchKeyword) ||
    // 			item.identifier?.toLowerCase().includes(searchKeyword) ||
    // 			item.phone_number?.replace(/-/g, '').includes(searchKeyword.replace(/-/g, ''))
    // 		)
    // 		: receiverMemberList;
    // 	const startIndex = page * count;
    // 	const endIndex = startIndex + count;
    // 	const currentTestData = filteredData.slice(startIndex, endIndex);
    // 	setTestData(currentTestData);
    // 	setTotalCount(filteredData.length);
    // }, [searchOption, page, count, receiverMemberList]);
    useEffect(() => {
        const searchKeyword = searchOption.keyword?.trim().toLowerCase() || '';
        // Apply search filter (Name | ID | Phone Number)
        const filteredData = searchKeyword !== ''
            ? receiverMemberList.filter(item => item.data[0]?.toLowerCase().includes(searchKeyword) || // Name (with identifier)
                item.data[1]?.replace(/-/g, '').includes(searchKeyword.replace(/-/g, '')) // Phone Number (without hyphens)
            )
            : receiverMemberList;
        const startIndex = page * count;
        const endIndex = startIndex + count;
        const currentTestData = filteredData.slice(startIndex, endIndex);
        setNoNumberData(originalStudentData.filter(item => item.data[1] === '' || item.data[1] === '-'));
        setTestData(currentTestData);
        setTotalCount(filteredData.length);
    }, [searchOption, page, count, receiverMemberList]);
    useEffect(() => {
        const handleAddSelectedMembers = ({ members, setting }) => {
            const setData = setting === "everyStudent"
                ? setEveryStudentData
                : setting === "oneByOne"
                    ? setOneByOneData
                    : null;
            if (setData) {
                setData((prev) => {
                    const originalIds = new Set(originalStudentData.map(member => member.id));
                    const filteredPrev = prev.filter(member => !originalIds.has(member.id));
                    return [...filteredPrev, ...members];
                });
            }
        };
        APP.eventManager.subscribe("ADD_SELECTED_MEMBERS", handleAddSelectedMembers);
        return () => {
            APP.eventManager.unsubscribe("ADD_SELECTED_MEMBERS", handleAddSelectedMembers);
        };
    }, [originalStudentData]); // ✅ No need to depend on memberSetting anymore
    const handlerMoreData = (page) => {
        setPage(page);
    };
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = [
            ...(!isMobile ? isNormal ?
                [
                    { title: 'nameId', space: 1, sort: false },
                    { title: 'phoneNumber', space: 3, sort: false },
                ] : [
                { title: 'nameId', space: 1, sort: false },
                { title: 'phoneNumber', space: 1, sort: false },
                { title: 'substitute1', space: 1, sort: false },
                { title: 'substitute2', space: 1, sort: false },
                { title: 'substitute3', space: 1, sort: false },
                { title: 'substitute4', space: 1, sort: false },
            ] : []),
        ];
        const tableReadDataKeys = isNormal ? [
            { title: 'nickname', space: 1, sort: false },
            { title: 'phone_number', space: 3, sort: false },
        ] : [
            { title: 'nickname', space: 1, sort: false },
            { title: 'phone_number', space: 1, sort: false },
            { title: 'substitute1', space: 1, sort: false },
            { title: 'substitute2', space: 1, sort: false },
            { title: 'substitute3', space: 1, sort: false },
            { title: 'substitute4', space: 1, sort: false },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isNormal, isMobile]);
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        setPage(0);
        setSearchOption(filter);
    };
    const onDelete = () => {
        const deleteMemberList = (data) => data.filter(item => !checkList.includes(item.id));
        const setDataFunction = {
            everyStudent: setEveryStudentData,
            oneByOne: setOneByOneData,
            excelUpload: setExcelUploadData,
        }[memberSetting];
        if (!setDataFunction)
            return;
        setDataFunction(prev => {
            let updatedList;
            // if (memberSetting === 'everyStudent') {
            // 	const [originalStudents, newStudents] = prev.reduce(
            // 		([orig, added], student) => {
            // 			(originalStudentData.some(original => original.id === student.id) ? orig : added).push(student);
            // 			return [orig, added];
            // 		},
            // 		[[], []]
            // 	);
            // 	if (checkList.some(id => originalStudentData.some(original => original.id === id))) {
            // 		alert("새롭게 추가된 수강생만 삭제할 수 있습니다.");
            // 	}
            // 	updatedList = [...originalStudents, ...deleteMemberList(newStudents)];
            // } else {
            updatedList = deleteMemberList(prev);
            // }
            setPage(updatedList.length > count ? Math.floor(updatedList.length / count) - 1 : 0);
            setTotalCount(updatedList.length);
            return updatedList;
        });
    };
    const handlerDelete = () => {
        if (checkList.length <= 0)
            return;
        if (isMobile) {
            onDelete();
        }
        else {
            APP.eventManager.publish(TOGGLE_POPUP, {
                component: DeletePopUpLayout,
                componentProps: {
                    deleteContent: checkList.length
                        ? t('assignment.delteInto', { count: checkList?.length })
                        : t('common.notSelect'),
                    onDelete: onDelete,
                },
                width: 639,
                title: t(`sendSMS.deleteMember`),
                isFit: true,
            });
        }
    };
    const validatePhoneNumber = phoneNumber => {
        // 한국 전화번호 형식 검증: 010-1234-5678, 01012345678, 011-123-4567, 016-123-4567 등
        const phoneRegex = /^(01[016789]-?\d{3,4}-?\d{4})$/;
        return phoneRegex.test(phoneNumber);
    };
    //전화번호 추가
    const handlerAdd = () => {
        if (inputValues[0].value === '') {
            return alert(t('sendSMS.enterName'));
        }
        if (!validatePhoneNumber(inputValues[1].value)) {
            return alert(t('sendSMS.retypeNumber'));
        }
        if (receiverMemberList.some(item => item.data[1] === inputValues[1].value)) {
            return alert(t('sendSMS.invalidPhoneNumber'));
        }
        // const newMember = {
        // 	id: inputValues[1].value,
        // 	creation_time: null,
        // 	status: 'inactive',
        // 	tenant_id: '',
        // 	level: '',
        // 	level_name: '',
        // 	identifier: '-', //(ID)
        // 	teacher_code: '',
        // 	phone_number: inputValues[1].value,
        // 	nickname: inputValues[0].value,
        // 	substitute1: inputValues[2].value,
        // 	substitute2: inputValues[3].value,
        // 	substitute3: inputValues[4].value,
        // 	substitute4: inputValues[5].value
        // };
        const newMember = {
            id: inputValues[1].value,
            data: [
                inputValues[0].value,
                inputValues[1].value,
                inputValues[2].value,
                inputValues[3].value,
                inputValues[4].value,
                inputValues[5].value
            ]
        };
        const setDataFunction = memberSetting === 'everyStudent'
            ? setEveryStudentData
            : memberSetting === 'oneByOne'
                ? setOneByOneData
                : memberSetting === 'excelUpload'
                    ? setExcelUploadData
                    : null;
        if (setDataFunction) {
            setDataFunction(prev => [...(prev || []), newMember]);
        }
        // Reset input values
        setInputValues(substitutes.map(type => ({ type, value: '' })));
    };
    // 한 건씩 추가 - 수신자 선택 팝업
    const handlerOpenMemberList = () => {
        const checkedList = receiverMemberList.filter(item => originalStudentData.some(original => original.id === item.id)).map(item => item.id);
        APP.eventManager.publish(TOGGLE_POPUP, {
            className: 'send-popup',
            component: ChoiceMemberList,
            componentProps: {
                memberSetting: memberSetting,
                noNumberData: noNumberData,
                checkedList: checkedList,
                memberList: originalStudentData,
                isNotChecked: false,
                isPopup: true,
                isNormal: true,
                isRegist: true,
                isDetail: false,
            },
            width: isNormal ? 580 : 730,
            title: '수신자 목록',
            isFit: true,
        });
    };
    const renderTitle = useMemo(() => (React.createElement(SendMemberHeader, { searchOption: searchOption.keyword, handlerFilterList: handlerFilterList, handlerDelete: handlerDelete, totalTableList: totalCount, isPopup: false, isRegist: true })), [searchOption, totalCount, checkList, handlerFilterList, handlerDelete]);
    // const renderItem = (item: SendMemberTableProps, styles: TableStyleProps) => {
    // 	console.log('item', item);
    // 	if (isMobile) return tableReadDataKeys.map((headItem, index) => (
    // 		<p className="overText2">
    // 			{(isNormal && headItem.title === 'nickname')
    // 				? `${item[headItem.title]} (${item['identifier']})`
    // 				: item[headItem.title] || '-'}
    // 			{index < tableReadDataKeys.length - 1 && " / "}
    // 		</p>
    // 	));
    // 	return tableReadDataKeys.map(headItem => (
    // 		<div
    // 			key={headItem.title}
    // 			className="body-item"
    // 			style={{
    // 				flex: !isMobile && headItem.space,
    // 				...styles?.bodyItem,
    // 			}}
    // 		>
    // 			<p className="overText2">
    // 				{headItem.title === 'nickname'
    // 					? `${item[headItem.title]} (${item['identifier']})`
    // 					: item[headItem.title]}
    // 			</p>
    // 		</div>
    // 	));
    // };
    const formatPhoneNumber = (phone) => {
        if (!phone)
            return "-";
        return phone.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1-$2-$3");
    };
    const renderItem = (item, styles) => {
        if (isMobile)
            return tableReadDataKeys.map((headItem, index) => (React.createElement("p", { className: "overText2" },
                index === 1 ? formatPhoneNumber(item.data[index]) : item.data[index] || "-",
                index < tableReadDataKeys.length - 1 && " / ")));
        return tableReadDataKeys.map((headItem, index) => (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                flex: !isMobile && headItem.space,
                ...styles?.bodyItem,
            } },
            React.createElement("p", { className: "overText2" }, index === 1 ? formatPhoneNumber(item.data[index]) : item.data[index] || "-"))));
    };
    const handleChange = value => {
        if (/^[\d]*$/.test(value)) {
            // setSendNumberValue(value);
            setInputValues(prev => {
                const newInputValues = [...prev];
                newInputValues[1].value = value;
                return newInputValues;
            });
        }
        else if (/^[\d-]*$/.test(value)) {
            alert(t('sendSMS.excludeBar'));
        }
        else {
            alert(t('sendSMS.onlyNumber'));
        }
    };
    const handleInputChange = (index, newValue) => {
        if (index === 1) {
            handleChange(newValue);
        }
        else {
            setInputValues(prev => prev.map((item, i) => i === index ? { ...item, value: newValue } : item));
        }
    };
    const renderMembersTableHeader = useMemo(() => {
        return (React.createElement("div", { className: "header" },
            React.createElement("div", { className: "field-global-001", style: { flexDirection: isMobile && 'column' } },
                React.createElement("div", { className: "fext-field", style: { flexDirection: isMobile && !isNormal && 'column' } }, isNormal ? (React.createElement(React.Fragment, null,
                    React.createElement(InputElement, { className: "add-input", type: "text", placeholder: "\uC774\uB984", value: inputValues[0]?.value || '', onChange: (value) => handleInputChange(0, value), style: { flex: 1 } }),
                    React.createElement(InputElement, { className: "add-input", type: "text", placeholder: "\uC804\uD654\uBC88\uD638", value: inputValues[1]?.value || '', onChange: (value) => handleChange(value), style: { flex: 4, minWidth: '102px' } }))) : (isMobile ? (React.createElement(React.Fragment, null, inputValues.reduce((rows, item, index) => {
                    if (index % 3 === 0)
                        rows.push([]); // Start a new row every 3 items
                    rows[rows.length - 1].push(React.createElement(InputElement, { key: index, className: "substitute-input", type: "text", placeholder: item.type, value: item.value, onChange: (value) => handleInputChange(index, value), style: { flex: 1, minWidth: '30%' } }));
                    return rows;
                }, []).map((row, rowIndex) => (React.createElement("div", { key: rowIndex, style: { display: 'flex', gap: '10px' } }, row))))) : (inputValues.map((item, index) => (React.createElement(InputElement, { key: index, className: "substitute-input", type: "text", placeholder: item.type, value: item.value, onChange: (value) => handleInputChange(index, value), style: !isMobile && index === 1 ? { minWidth: '102px' } : {} })))))),
                React.createElement("div", { className: "field-global-001", style: { width: isMobile ? '100%' : 'auto' } },
                    React.createElement(Button, { className: "btn_tur xmd", text: "\uCD94\uAC00", onClick: handlerAdd, styles: {
                            buttonWrap: {
                                flex: isMobile && '1',
                            },
                        } }),
                    isNormal && memberSetting !== "excelUpload" && (React.createElement(Button, { className: "btn_tur xmd", text: t('sendSMS.selectMember'), onClick: handlerOpenMemberList, styles: {
                            buttonWrap: {
                                flex: isMobile && '1',
                            },
                        } }))))));
    }, [isNormal, memberSetting, setInputValues, isMobile, handleChange, inputValues, handleInputChange, handlerAdd, handlerOpenMemberList, originalStudentData, everyStudentData, oneByOneData, receiverMemberList, setReceiverMemberList]);
    return (React.createElement(MemberListStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop, className: `open-member-list` },
        React.createElement("div", { className: "member-setting" },
            React.createElement("div", { className: "setting-checkbox" },
                React.createElement("p", { className: "overText", style: { flexShrink: 0 } }, t('sendSMS.setMember')),
                React.createElement(CheckBox, { iconSize: isDesktop ? 24 : 20, isRadioButton: true, list: isNormal ? SMS_MEMBER_CHECK_LIST : SMS_MEMBER_CHECK_LIST.slice(1, 3), defaultCheckId: memberSetting, handlerCheck: (id, checked) => {
                        setMemberSetting(id);
                    } })),
            React.createElement("div", { className: "excel-wrap", style: { display: memberSetting === 'excelUpload' ? 'flex' : 'none' } },
                React.createElement(SMSUploadExcel, { receiverMemberList: receiverMemberList, setExcelUploadData: setExcelUploadData }))),
        React.createElement("div", { className: "member-list" },
            renderMembersTableHeader,
            React.createElement("div", { className: "member-wrap" },
                React.createElement(MembersTableStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "user-list" },
                    renderTitle,
                    React.createElement(Table, { className: "sms-member-list", forceShowheader: true, showCheckBox: true, 
                        // defaultCheckList={defaultCheckMembers ? defaultCheckMembers : []}
                        // disableIdList={
                        // 	receiverMemberList ? receiverMemberList.map(item => item.id) : []
                        // }
                        disableIdList: noNumberData.map(item => item.id), isInifinite: false, data: testData, totalCount: totalCount, page: page, pagingCount: 5, showCount: count, sortDefault: "id", headTitleList: headTitleList, renderItem: renderItem, handlerCheckList: setCheckList, handlerMoreData: handlerMoreData, isKeepChecked: true, renderGuidItem: React.createElement(React.Fragment, null) }))))));
});
