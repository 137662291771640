import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button } from '../../../../common';
import { useConferenceList } from '../../../../server';
import { getIsTeacher } from '../../account';
import { goToConference } from '../../../DST/DSTT001/funtions';
import { TOGGLE_POPUP } from '../../popup';
const LiveRoomListPopUpWrapper = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 12px;

    .live-room-list-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 16px 0;
        gap: 4px;
    }

    .title-count {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.02em;
    }

    .room-count {
        color: #f46790;
    }

    .title-guide {
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: var(--color-grey, #666666);
    }

    .live-room-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .room-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 0;
        gap: 4px;
        border-bottom: 1px solid var(--color-silver, #f3f4f8);
    }

    .room-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        gap: 4px;
    }

    .room-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.02em;
    }

    .room-detail {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: var(--color-grey-l, #83868a);
    }

    .reservation-btn {
        padding: 0;
        width: 60px;
        height: 26px;
        p {
            font-size: 12px;
            line-height: 22px;
        }
    }
`;
export const LiveRoomListPopUp = () => {
    const { t } = useTranslation();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const [roomList, setRoomList] = useState([]);
    const { data, refetch } = useConferenceList({
        status: ['held', 'booked'],
        orderby: ['startline ASC'],
    });
    useEffect(() => {
        refetch();
    }, [isTeacher]);
    useEffect(() => {
        if (data) {
            const { total_count, items, server_time } = data;
            if (total_count > 0) {
                if (isTeacher) {
                    setRoomList(items);
                }
                else {
                    const now = new Date(server_time).getTime();
                    const filteredItems = items.filter((item) => {
                        if (item.status === "held")
                            return true;
                        const startTime = new Date(item.start_time).getTime();
                        const preEnteringTime = item.pre_entering_duration * 60 * 1000;
                        return now >= startTime - preEnteringTime && now <= startTime;
                    });
                    setRoomList(filteredItems);
                }
            }
            else {
                setRoomList([]);
            }
        }
    }, [data, isTeacher]);
    function formatDateTime(dateTime) {
        const date = new Date(dateTime);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    }
    const renderTitle = useMemo(() => {
        return (React.createElement("div", { className: "live-room-list-title" },
            React.createElement("div", { className: "title-count" },
                "\uC785\uC7A5 \uAC00\uB2A5\uD55C \uAC15\uC758\uC2E4\uC774",
                " ",
                React.createElement("span", { className: "room-count" }, roomList.length),
                " ",
                "\uAC1C \uC788\uC2B5\uB2C8\uB2E4."),
            React.createElement("div", { className: "title-guide" }, "\uC785\uC7A5\uD560 \uAC15\uC758\uC2E4\uC744 \uC120\uD0DD\uD574\uC8FC\uC138\uC694.")));
    }, [roomList]);
    const renderList = useMemo(() => {
        return (React.createElement("div", { className: "live-room-list" }, roomList.map((item, index) => (React.createElement("div", { className: "room-item", key: index },
            React.createElement("div", { className: "room-details" },
                React.createElement("div", { className: "room-title" }, item.title),
                React.createElement("div", { className: "room-detail" }, `${formatDateTime(item.start_time)} ${item.progress_duration}분 (${item.pre_entering_duration}분 전 입장)`),
                React.createElement("div", { className: "room-detail" }, item.host_name)),
            React.createElement(Button, { className: "reservation-btn", text: "\uC785\uC7A5", onClick: () => goToConference(item.id) }))))));
    }, [roomList]);
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    return (React.createElement(LiveRoomListPopUpWrapper, null,
        renderTitle,
        renderList,
        React.createElement(Button, { text: "\uCDE8\uC18C", className: "btn_white xmd", onClick: closePopup })));
};
