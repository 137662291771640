import { throttle } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TOGGLE_POPUP } from '../../../base/popup';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { getClassesId } from '../../../base/classes';
import { postExcelUpload } from '../../../../server/sms';
import { useMutation } from 'react-query';
import { useMemberInfo } from '../../../../server';
import { FileAttach } from '../../../../common/option';
const UploadPopUpStyled = styled.div `
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 12px;

	.pink {
		color: var(--color-pink, #f46790);
		text-align: center;
		font-family: var(--b1-font-family, 'Noto Sans KR', sans-serif);
		font-weight: var(--b1-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.text {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);

		font-weight: var(--b3-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.body-wrap {
		width: 100%;
		white-space: break-spaces;
		text-align: center;
	}

	.excel-download {
		border-radius: 4px;
		padding: 4px 6px;
	}

	${props => (props.$isMobile ? mobileStyled : props.$isTablet ? tabletStyled : desktopStyled)}
`;
const mobileStyled = css `
	.pink {
		font-size: var(--b1-font-size, 18px);
		line-height: 150%;
		letter-spacing: var(--b1-letter-spacing, -0.02em);
	}
	.text {
		font-size: var(--b3-font-size, 14px);
		line-height: 150%;
		letter-spacing: var(--b3-letter-spacing, -0.02em);
	}

	.sms-upload-excel {
		display: flex;
		flex: 1;
	}

	.sample-file-download {
		padding: 10px 0 !important;
	}
`;
const tabletStyled = css `
	.pink {
		font-size: var(--b1-font-size, 22px);
		line-height: var(--b1-line-height, 42px);
		letter-spacing: var(--b1-letter-spacing, -0.02em);
	}
	.text {
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		letter-spacing: var(--b3-letter-spacing, -0.02em);
	}
`;
const desktopStyled = css `
	.pink {
		font-size: var(--b1-font-size, 20px);
		line-height: var(--b1-line-height, 42px);
		letter-spacing: var(--b1-letter-spacing, -0.02em);
	}

	.text {
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		letter-spacing: var(--b3-letter-spacing, -0.02em);
	}
`;
export const SMSUploadExcel = React.memo(({ receiverMemberList, setExcelUploadData, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const classesId = getClassesId();
    const [file, setFile] = React.useState([]); //파일
    const [fileName, setFileName] = React.useState(''); //파일명
    const [receivers, setReceivers] = React.useState([]); //업로드 할 수신자 목록
    const [memberData, setMemberData] = React.useState([]); //아이디로 조회한 수신자 목록
    const [noPhoneMemberList, setNoPhoneMemberList] = React.useState([]); //번호가 없는 사용자 목록
    const [isUpload, setIsUpload] = React.useState(false); //업로드 성공 여부
    const excelUploadMutation = useMutation(postExcelUpload);
    useEffect(() => {
        if (file.length > 0) {
            setFileName(file[0].name);
        }
        else {
            setFileName('');
            setExcelUploadData([]);
            setIsUpload(false);
        }
    }, [file]);
    const handleUpload = async () => {
        try {
            const response = await excelUploadMutation.mutateAsync(file[0]);
            if (response) {
                setIsUpload(true);
                if (response.result.receivers.length === 0) {
                    setIsUpload(false);
                }
                else {
                    setReceivers(response.result.receivers);
                }
                if (response.result.skip > 0) {
                    alert(`${t('sendSMS.excelSkip', { skip: response.result.skip })}`);
                }
                else {
                    alert(t('sendSMS.fileUploadOk'));
                }
            }
            else {
                setIsUpload(false);
            }
        }
        catch (error) {
            alert(t('sendSMS.fileUploadFail'));
            console.error('Error Excel Upload ', error);
            setIsUpload(false);
        }
    };
    useEffect(() => {
        if (fileName !== '') {
            handleUpload();
        }
    }, [fileName]);
    useEffect(() => {
        if (isUpload) {
            handlerAdd();
        }
    }, [isUpload]);
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    const { data, refetch } = useMemberInfo({
        class_id: classesId,
        id: receivers,
        // status: 'created',
        level: ['03'],
    });
    useEffect(() => {
        if (data) {
            const { items } = data;
            setMemberData(items.filter(data => data.phone_number !== '-'));
            setNoPhoneMemberList(items.filter(data => data.phone_number === '-'));
        }
    }, [data]);
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        refetchList();
    }, [receivers]);
    // const handlerAdd = () => {
    // 	if (receivers.length > 0) {
    // 		const newReceiverList = receivers.filter(
    // 			item => !memberData.map(member => member.id).includes(item)
    // 		);
    // 		console.log('newReceiverList', newReceiverList);
    // 		console.log('receivers', receivers);
    // 		const receiverList: any = newReceiverList
    // 			.filter(item => item.length <= 11) //아이디로 등록하는 사람 중 번호가 없는 사용자를 제외하기 위해
    // 			.map((item, index) => ({
    // 				id: item[1],
    // 				creation_time: null,
    // 				identifier: '-',
    // 				level: '',
    // 				level_name: '',
    // 				phone_number: item[1],
    // 				nickname: item[0],
    // 				status: 'inactive',
    // 				teacher_code: '',
    // 				tenant_id: '',
    // 				substitute1: item[2] ? item[2] : '-',
    // 				substitute2: item[3] ? item[3] : '-',
    // 				substitute3: item[4] ? item[4] : '-',
    // 				substitute4: item[5] ? item[5] : '-',
    // 			}));
    // 		//@ts-ignore
    // 		setExcelUploadData(prevData => {
    // 			const list = [
    // 				...(prevData ? [...prevData] : []),
    // 				// ...memberData.filter(data => !prevData.some(item => item.id === data.id)),
    // 				...receiverList.filter(data => !prevData.some(item => item.id === data.id)),
    // 			];
    // 			return list;
    // 		});
    // 		// if (receiverList.length + memberData.length !== receivers.length) {
    // 		// 	alert(
    // 		// 		`${t('sendSMS.noPhoneNumber')} (${noPhoneMemberList.map(
    // 		// 			item => item.identifier
    // 		// 		)})`
    // 		// 	);
    // 		// }
    // 		closePopup();
    // 	} else {
    // 		alert(t('sendSMS.checkFile'));
    // 	}
    // };
    const handlerAdd = () => {
        console.log('receivers', receivers);
        if (receivers.length > 0) {
            const newReceiverList = receivers.filter(item => !receiverMemberList.some(receiver => receiver.data[1] === item[1]));
            const duplicateCount = receivers.length - newReceiverList.length;
            if (duplicateCount > 0) {
                alert(`${t('sendSMS.fileAddCount', { count: newReceiverList.length })}`);
            }
            const receiverList = newReceiverList
                .filter(item => item[1].length <= 11)
                .map((item) => ({
                id: item[1],
                data: [
                    item[0],
                    item[1],
                    item[2] ? item[2] : '-',
                    item[3] ? item[3] : '-',
                    item[4] ? item[4] : '-',
                    item[5] ? item[5] : '-',
                ]
            }));
            console.log('receiverList', receiverList);
            setExcelUploadData(prevData => {
                const list = [
                    ...(prevData ? [...prevData] : []),
                    ...receiverList.filter(data => !prevData.some(item => item.id === data.id)),
                ];
                return list;
            });
            closePopup();
        }
        else {
            alert(t('sendSMS.checkFile'));
        }
    };
    function download(url) {
        // fetch(url)
        // 	.then(response => response.blob())
        // 	.then(blob => {
        // 		const link = document.createElement('a');
        // 		link.href = URL.createObjectURL(blob);
        // 		link.download = '수신자 다운로드 양식.xlsx';
        // 		link.click();
        // 	})
        // 	.catch(console.error);
    }
    const handlerDownload = async () => {
        const url = `/api/mimac/v1/messages/download-excel-form?&class_id=${classesId}&format=true`;
        window.location.href = url;
    };
    return (React.createElement(UploadPopUpStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "upload-popup" },
        React.createElement(FileAttach, { id: "sms-upload-excel", isRegist: true, isNotice: true, fileList: file, setAttachFileList: setFile, isSMSRegist: true, handlerDownload: handlerDownload })));
});
