import throttle from 'lodash/throttle';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Button } from '../../../common';
import { postDeleteSendSMS, useSMSReceiversList, useSendSMSList, } from '../../../server/sms';
import { DeletePopUpLayout } from '../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../base/popup';
import { SMS_STATUS } from './constant';
import { ChoiceMemberList } from './DSTL007_Send_Child/ChoiceMemberTable';
import { useDispatch } from 'react-redux';
import { updateMenu } from '../../menu/action';
import { substitutes } from './DSTL007_Send_Child/SendSMSRegist';
const commonStyles = css `
	background: #fcfcfc;
	border-radius: 0px 0px 12px 12px;
	width: 100%;
	display: flex;

	.fext-field {
		border: none;
	}

	.open-contents {
		width: 100%;
		display: flex;
	}

	.notice-content {
		width: 100%;
	}

	.receiver-wrap {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.button {
			background: var(--bg-bgw1, #f3f4f8);
			border-radius: 100px;
			padding: 8px 10px 8px 10px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			row-gap: 0px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--font-dgrey, #4d4d4d);
				text-align: left;
				font-family: 'Noto Sans KR', sans-serif;
				font-size: 10px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
			}
		}
	}

	.setting_box {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		position: relative;
		text-overflow: ellipsis;
		white-space: nowrap;
		align-items: flex-start;

		.receiver-count {
			color: var(--color-grey, #666666);
			text-align: left;
			font-family: var(--b3-font-family, 'Noto Sans KR', sans-serif);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.teacher-info {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		padding: 0px 0px 8px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		text-align: left;
		font-family: var(--b4-font-family, 'Noto Sans KR', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		letter-spacing: var(--b4-letter-spacing, -0.02em);
		font-weight: var(--b4-font-weight, 400);
		color: var(--color-grey, #666666);

		.name {
			display: flex;
			flex-direction: row;
			gap: 12px;
		}
	}

	.btn-container {
		padding-top: 0px;
		display: flex;
		flex-direction: row;
		width: 100%;
		margin: 0px;
	}

	.underline {
		text-decoration: underline;
	}

	.green {
		color: var(--tur-d, #479da6);
	}

	.pink {
		color: var(--color-accent, #e7424c);
	}

	.open-contents {
		background: var(--color-white, #ffffff);
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: var(--shadow-bg-box-shadow, 0px 2px 6px 0px rgba(224, 226, 235, 0.4));
		overflow: auto;

		color: var(--natural-color-n-0, #2f2f2f);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
	}

	.notice-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		color: var(--natural-color-n-0, #2f2f2f);
		text-align: left;
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;

		.img_wrap {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.img {
				border-radius: 4px;
				flex-shrink: 0;
				width: 100px;
				height: 100px;
				position: relative;
				object-fit: cover;
				background: #000;
			}
		}
	}

	svg {
		min-width: 16px;
	}
`;
const mobileStyles = css `
	padding: 10px;

	.setting_box {
		flex-wrap: prev-wrap !important;

		p {
			font-size: 12px;
		}
	}

	.md_round {
		padding: 2px 8px;
		height: 30px;
	}

	.receiver-wrap {
		gap: 4px;
	}

	.receiver {
		p {
			padding-bottom: 3px;
			max-width: 60px;
			min-width: fit-content;
			font-size: var(--b-l-medium-font-size, 12px);
			line-height: var(--b-l-medium-line-height, 22px);
		}
	}

	.receiver-count {
		font-size: 12px;
		line-height: 150%;
		letter-spacing: -0.02em;
	}

	.open-contents {
		padding: 16px 20px;
	}

	.teacher-info {
		border-width: 0px 0px 0.5px 0px;
		.active-bar {
			.button p {
				min-width: fit-content;
				font-size: 12px;
			}
		}
	}

	.notice-content {
		gap: 12px;
		.img_wrap {
			gap: 8px;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}
		.img_pair {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}

	.xmd {
		padding: 8px 10px !important;
		height: 38px;
	}
`;
const tabletStyles = css `
	padding: 20px;

	.receiver {
		p {
			max-width: 60px;
			min-width: fit-content;
			font-size: 10px;
			line-height: 150%;
		}
	}

	.receiver-wrap {
		gap: 8px;
	}

	.receiver-count {
		font-size: 12px;
		line-height: 150%;
		letter-spacing: -0.02em;
	}

	.receiver-wrap {
		π button {
			padding: 8px 10px;
		}
	}

	.open-contents {
		padding: 20px;
		overflow: hidden;
	}

	.notice-content {
		overflow: auto;
		.img_wrap {
			gap: 12px;
		}
	}

	.teacher-info {
		border-width: 0px 0px 1px 0px;
		.active-bar {
			.button p {
				min-width: fit-content;
			}
		}
	}

	.setting_box {
		padding: 20px 10px 10px 10px;
	}

	.quill_wrap {
		font-size: 16px;
	}
`;
const desktopStyles = css `
	padding: 30px;

	.receiver {
		p {
			max-width: 60px;
			min-width: fit-content;
			font-size: 10px;
			line-height: 150%;
		}
	}

	.quill_wrap {
		font-size: 16px;
	}

	.receiver-wrap {
		gap: 12px;
	}

	.receiver-count {
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		letter-spacing: var(--b3-letter-spacing, -0.02em);
	}

	.open-contents {
		padding: 24px 28px;
		overflow: hidden;
	}

	.notice-content {
		overflow: auto;
	}

	.teacher-info {
		border-width: 0px 0px 1px 0px;
		.active-bar {
			.button p {
				min-width: fit-content;
			}
		}
	}

	.setting_box {
		padding: 20px 10px 10px 10px;
	}
`;
const DSTL007DetailStyled = styled.div `
	${commonStyles}
	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
export const DSTL007_Detail = ({ selectedId, setSelectedId, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [item, setItem] = React.useState(undefined);
    const [receiversList, setReceiverList] = React.useState([]);
    const previewRef = React.useRef(null);
    const deletePostMutation = useMutation(postDeleteSendSMS);
    const { data, refetch } = useSendSMSList({
        id: selectedId,
    });
    const { data: receiversData, refetch: receiversRefetch } = useSMSReceiversList({
        id: selectedId,
    });
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    const refetchReceiversList = throttle(() => receiversRefetch(), 1000, { leading: true, trailing: false });
    useEffect(() => {
        if (selectedId) {
            refetchList();
            refetchReceiversList();
        }
        else {
            setItem(undefined);
        }
    }, [selectedId]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            if (items && items.length >= 1) {
                const item = items[0];
                setItem(item);
                return;
            }
        }
        setItem(undefined);
    }, [data]);
    useEffect(() => {
        if (receiversData) {
            const { receivers } = receiversData;
            if (receivers && receivers.length >= 1) {
                const simpleItems = receivers.map(item => ({
                    id: item[1],
                    data: [
                        item[0],
                        item[1]
                    ]
                }));
                setReceiverList(simpleItems);
                return;
            }
        }
    }, [receiversData]);
    useEffect(() => {
        if (!previewRef.current)
            return;
        const previewTitle = item?.title.length > 0 ? `<span style="font-weight: bold;">${item.title}</span><br>` : '';
        const preview = previewTitle + item?.content;
        if (!preview) {
            previewRef.current.innerHTML = "<span style='color: gray;'>내용 없음</span>";
            return;
        }
        const highlightedText = preview.replace(/#\{(.*?)\}/g, (match, word) => {
            return substitutes.includes(word)
                ? `<span style="color: #8268d9;">${match}</span>`
                : match;
        });
        previewRef.current.innerHTML = highlightedText;
    }, [item, substitutes]);
    //게시글 삭제
    const handleDelete = () => {
        const onDelete = async () => {
            const id = item.id;
            try {
                APP.eventManager.publish('SHOW_LOADING', true);
                await deletePostMutation.mutateAsync(id);
                APP.eventManager.publish('UPDATE_SEND_SMS');
                if (selectedId === id)
                    setSelectedId(undefined);
            }
            catch (error) {
                console.error('Failed to post deletePost:', error);
                alert(error);
            }
            finally {
                APP.eventManager.publish('SHOW_LOADING', false);
            }
        };
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: t('assignment.delteInto', { count: 1 }),
                onDelete,
            },
            width: 639,
            title: t(`supportRoom.noticeDelete`),
            isFit: true,
        });
    };
    //게시글 수정
    const handleEdit = () => {
        dispatch(updateMenu(undefined, { menu: 'sendSMS', detail: 'regist' }, selectedId));
    };
    //수신자 더보기 클릭 시 팝업창 이동
    const handleMoreReceiver = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            className: 'send-popup',
            component: ChoiceMemberList,
            componentProps: {
                memberList: receiversList,
                isNotChecked: true,
                isPopup: true,
                isDetail: true,
            },
            width: 580,
            title: '수신자 목록',
            isFit: true,
        });
    };
    const { isMobile, isTablet } = useGlobalState();
    if (!item)
        return null;
    return (React.createElement(DSTL007DetailStyled, { className: "open-contents", "$isMobile": isMobile, "$isTablet": isTablet },
        React.createElement("div", { className: "notice-content" },
            React.createElement("div", { className: "sms-preview-content", ref: previewRef }),
            React.createElement("div", { className: "fext-field setting_box" },
                React.createElement(Button, { className: "button btn_line_light_tur md_round receiver", text: `수신자 (
							${receiversList.length === 0 ? '00' : receiversList.length.toString().padStart(2, '0')}
							) 명`, option: {
                        buttonAfterIcon: {
                            show: true,
                            className: 'arrow-right',
                            name: 'twoarrow',
                            size: 16,
                        },
                    }, onClick: handleMoreReceiver })),
            React.createElement("div", { className: "btn-container" },
                item.status === SMS_STATUS.CREATED && (React.createElement(Button, { className: "btn_line_tur xmd", text: 'common.modify', onClick: handleEdit, styles: { buttonWrap: { flex: 1 } } })),
                React.createElement(Button, { className: "btn_line_pink xmd", text: 'common.delete', onClick: handleDelete, styles: { buttonWrap: { flex: 1 } } })))));
};
