import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SearchBar } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const MemberHeaderStyled = styled.div `
	&.header {
		display: flex;
		flex-direction: ${props => (props.$isMobile ? 'column' : 'row')};
		align-items: ${props => (props.$isMobile ? 'flex-start' : 'center')};
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		gap: ${props => (props.$isMobile ? '12px' : '0')};

		.active-bar {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.member {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--h3-font-family, 'Noto Sans KR', sans-serif);
			font-size: var(--h5-font-size, 16px);
			line-height: var(--h5-line-height, 24px);
			font-weight: var(--h5-font-weight, 700);
			position: relative;
			width: 128px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
`;
export const MemberHeader = React.memo(({ handlerFilterList, isDetail, totalTableList = 0, }) => {
    const { isMobile, isTablet } = useGlobalState();
    const { t } = useTranslation();
    return (React.createElement(MemberHeaderStyled, { className: "header", "$isMobile": isMobile },
        React.createElement("div", { className: "member" }, t('conference.memberCount', {
            count: totalTableList,
        })),
        !isDetail && (React.createElement("div", { className: "active-bar", style: { gap: 14, height: '36px' } },
            React.createElement(SearchBar, { className: `search-bar-${isMobile ? 'mobile' : isTablet ? 'tablet' : 'big'}`, filterData: "nickname_like", handlerFilterList: handlerFilterList })))));
});
